import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input, InputGroup, Row } from "reactstrap";

import RegionSelector from "../RegionSelector";

import {
  saveCustomerAccountName,
  setAccountName,
} from "../../../actions/account";
import { isNotEmptyString } from "../../../../../utils/format-string";

import accountStyles from "../Account.module.scss";
import firstRunStlyes from "../../../../new-first-run/components/NewFirstRun.module.scss";

const SetAccountDetails = ({ firstRunView, selectedRegions }) => {
  const { t } = useTranslation("addAccount");
  const dispatch = useDispatch();

  const {
    accountNumber,
    customerAccountNameSaved,
    customerAccountId,
    savingAccountName,
    accountName,
  } = useSelector(({ addAccount }) => {
    return {
      accountNumber: addAccount.accountNumber,
      customerAccountNameSaved: addAccount.customerAccountNameSaved,
      customerAccountId: addAccount.customerAccountId,
      savingAccountName: addAccount.savingAccountName,
      accountName: addAccount.accountName,
    };
  }, shallowEqual);

  const handleSaveAccountName = useCallback(() => {
    const params = {
      AccountName: accountName,
    };
    dispatch(saveCustomerAccountName(customerAccountId, params));
  }, [dispatch, accountName, customerAccountId]);

  const disableConfirmButton = useMemo(
    () =>
      customerAccountNameSaved ||
      savingAccountName ||
      !isNotEmptyString(accountName),
    [customerAccountNameSaved, savingAccountName, accountName]
  );

  const handleChangeAccountName = (event) => {
    dispatch(setAccountName(event.target.value));
  };

  return (
    <div
      className={classnames(
        firstRunView ? "bs4-text-left mt-0" : "bs4-text-center mt-5"
      )}
    >
      {!firstRunView && (
        <span className={accountStyles.loading}>
          <i className="fa fa-check-circle text-success" />
        </span>
      )}

      <Row className="bs4-m-0 bs4-mt-3">
        {!firstRunView && (
          <>
            <Col xl={3}></Col>
            <Col xl={6}>
              <h5>
                {t("accountConnectedMessage1")}&nbsp;
                <strong>{accountNumber} </strong>
                {t("accountConnectedMessage2")}&nbsp;
                {t("accountConnectedMessage3")}
              </h5>
            </Col>
          </>
        )}
      </Row>
      <Row className="bs4-m-0 bs4-mt-2">
        {!firstRunView && (
          <>
            <Col xl={4}></Col>
            <Col xl={4}>
              <FormGroup>
                <InputGroup>
                  <Input
                    className={classnames(accountStyles.addAccount)}
                    value={accountName}
                    onChange={handleChangeAccountName}
                    type="text"
                    required
                    name="name"
                    id="search-input1"
                    placeholder={t("newAccountNameTooltip")}
                    disabled={customerAccountNameSaved}
                  />
                  <button
                    className="btn btn-primary bs4-ml-2 btn-sm float-right"
                    onClick={handleSaveAccountName}
                    disabled={disableConfirmButton}
                  >
                    {!customerAccountNameSaved
                      ? t("btn.saveName")
                      : t("common:confirmed")}
                    &nbsp;
                    <i className="fa fa-check bs4-ml-2" />
                  </button>
                </InputGroup>
              </FormGroup>
              {!firstRunView && customerAccountNameSaved && (
                <div className="mc-text-xxsm-regular">
                  {t("accountNameSavedMessage")}
                </div>
              )}
            </Col>
          </>
        )}
      </Row>
      {firstRunView && (
        <>
          <div
            className={classnames(
              firstRunStlyes.MCHeading3,
              firstRunStlyes.Bold600,
              firstRunStlyes.Opacity
            )}
          >
            {t("firstRun:regionHeading")}
          </div>
          <RegionSelector
            regionsList={selectedRegions}
            firstRunView={firstRunView}
          />
        </>
      )}
    </div>
  );
};

export default SetAccountDetails;
