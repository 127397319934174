import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { PORTAL_TENANT_LIST } from "../../../config/service";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const portalTenantListApi = createApi({
  reducerPath: "portalTenantListAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPortalTenantsList: builder.query({
      query: () => {
        return {
          url: PORTAL_TENANT_LIST,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const { useGetPortalTenantsListQuery } = portalTenantListApi;
