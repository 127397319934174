import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "@hocs/withRouter";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import Widget from "../../../../components/Widget";
import {
  forgotPassword,
  authCaptchaVerification,
  authCaptchaVerificationFailed,
  receiveToken,
} from "../../../../modules/authentication/actions/auth";
import classNames from "classnames";
import {
  getLoginLink,
  getPartnerAltText,
  getPartnerBackgroundImageClassName,
  getPartnerButtonClassName,
  getPartnerLogo,
  getPartnerTextClassName,
  getPartnerType,
} from "../../../../helpers/auth-partner-helper-functions";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FORGOT_PASSWORD_PAGE } from "../signup/utils";
import analytics from "../../../../libs/Analytics/analytics";
import { FORGOT_PASSWORD_EVENT_CAPTCHA_ERROR } from "../../../../libs/Analytics/global-event-types";

class ForgotPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (!token) return;
    return true;
  }

  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const defaultEmail = params.get(decodeURIComponent("email"));

    this.state = {
      username: defaultEmail,
      partnerType: getPartnerType(),
    };

    this.forgotPassword = this.forgotPassword.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
  }

  changeUsername(event) {
    this.setState({ username: event.target.value });
  }

  forgotPassword(e) {
    e.preventDefault();
    const { googleReCaptchaProps } = this.props;
    if (!googleReCaptchaProps || !googleReCaptchaProps.executeRecaptcha) {
      const event = {
        eventName: FORGOT_PASSWORD_EVENT_CAPTCHA_ERROR,
        additionalProps: {
          email: this.state.username,
        },
      };
      analytics.track(event);
      return;
    }
    this.props.dispatch(authCaptchaVerification());
    googleReCaptchaProps
      .executeRecaptcha(FORGOT_PASSWORD_PAGE)
      .then((recaptchaToken) => {
        this.props.dispatch(
          forgotPassword({
            Username: this.state.username,
            CaptchaCode: recaptchaToken,
          })
        );
      })
      .catch((error) => {
        this.props.dispatch(authCaptchaVerificationFailed());
        const event = {
          eventName: FORGOT_PASSWORD_EVENT_CAPTCHA_ERROR,
          additionalProps: {
            email: this.state.username,
            error,
          },
        };
        analytics.track(event);
      });
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");
    if (token) {
      this.props.dispatch(receiveToken(token));
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: "AUTH_PAGE_UNLOADED" });
  }

  render() {
    const { t, isFetching } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    };

    // cant access login page while logged in
    if (ForgotPassword.isAuthenticated(localStorage.getItem("token"))) {
      return <Navigate to={from} />;
    }

    const loginLink = getLoginLink();

    const { username } = this.state;

    const partnerBackgroundImage = getPartnerBackgroundImageClassName(
      this.state.partnerType
    );

    return (
      <div className={classNames("auth-container", partnerBackgroundImage)}>
        <Row className="row-transparent-bg">
          <Col xl={12}>
            <Container className="padding-yaxis-10em min-height-100vh">
              {this.props.navigateToChangePassword ? (
                <Navigate
                  to={`/change-password?email=${encodeURIComponent(username)}`}
                />
              ) : (
                <Widget
                  className="widget padding-30px bs4-mx-auto"
                  title={
                    <h4
                      className={classNames(
                        "bs4-mt-3 bs4-mb-2 bs4-ml-2",
                        getPartnerTextClassName(this.state.partnerType)
                      )}
                    >
                      {t("forgotPassword")}
                    </h4>
                  }
                  logo={
                    <a
                      href="https://www.montycloud.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        className="logo"
                        src={getPartnerLogo(this.state.partnerType)}
                        alt={getPartnerAltText(this.state.partnerType)}
                      ></img>
                    </a>
                  }
                >
                  <form className="mt" onSubmit={this.forgotPassword}>
                    {this.props.errorMessage && (
                      <Alert className="alert-sm" color="danger">
                        {this.props.errorMessage}
                      </Alert>
                    )}
                    <FormGroup row>
                      <Col>
                        <InputGroup>
                          <Input
                            className="form-control"
                            value={username}
                            onChange={this.changeUsername}
                            type="email"
                            required
                            name="username"
                            placeholder={t("email")}
                          />
                          <span className="input-group-text">
                            <i className="fa fa-envelope-o" />
                          </span>
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    <div className="clearfix">
                      <div className="btn-toolbar float-right">
                        <button
                          type="submit"
                          href="/app"
                          disabled={isFetching}
                          className={classNames(
                            "btn btn-md",
                            getPartnerButtonClassName(this.state.partnerType)
                          )}
                        >
                          {isFetching
                            ? t("loading")
                            : t("sendVerificationCode")}
                        </button>
                      </div>
                    </div>
                  </form>
                  <h6 className="clearfix bs4-mt-3 breadcrumb-link">
                    <Link className="mt-sm bs4-mt-2 link" to={loginLink}>
                      {t("login")}
                    </Link>
                  </h6>
                </Widget>
              )}
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    navigateToChangePassword: state.auth.navigateToChangePassword,
    isAuthenticated: state.tokenReducer.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(
  connect(mapStateToProps)(
    withTranslation(["forgotPassword"])(withGoogleReCaptcha(ForgotPassword))
  )
);
