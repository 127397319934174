import { createBrowserHistory } from "history";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Progress } from "reactstrap";
import { onFCP, onLCP } from "web-vitals";

import config from "../config";
import { getLoggedInUserAccessToken } from "../helpers/account";
import analytics from "../libs/Analytics/analytics";
import {
  getLoggedInUserDetails,
  logoutUser,
} from "../modules/authentication/actions/auth";
import { getAllOrganizations } from "../modules/first-run/actions/firstRun";
import { getCustomerPreference } from "../modules/new-first-run/actions/new-first-run";
import { getRegions } from "../modules/region/actions/regions";
import { getUserData } from "../modules/user-data/actions/user-data";
import { APP_FIRST_RUN, CUSTOMER_ROLE_TYPE } from "../utils/app-constants.json";
import { FEATURE_SELECTION, SETUP_MFA_PATH } from "../utils/app-paths.json";
import { isArrayWithLength } from "../utils/array-methods";
import { getInitialPage } from "../utils/menu-items";
import MCLoader from "./Loader/MCLoader";

import "../styles/theme.scss";
import "../styles/bootstrap-prefixed.css";
import "../styles/app.scss"; //app.scss file is imported after the theme.scss file to allow custom styles to override the default Bootstrap classes.
import "../styles/text.scss";
import "../styles/fonts/font.scss";
import "@montycloud/mc-vision-ui/style.css";
import "../styles/tailwind-overrides.css";
import { PERFORMANCE_EVENT } from "../libs/Analytics/global-event-types";

import ChangePassword from "../modules/authentication/components/change-password";
import ForgotPassword from "../modules/authentication/components/forgot-password";
import Login from "../modules/authentication/components/login";
import ResetPassword from "../modules/authentication/components/reset-password";
import SignUpRiverMeadow from "../modules/authentication/components/signup/Signup-RiverMeadow";
import SignUp from "../modules/authentication/components/signup/SignupV1";
import VerifyEmail from "../modules/authentication/components/verify-email/VerifyEmail";
import VerifyMFA from "../modules/authentication/components/verify-mfa/VerifyMFA";
import InstanceRemoteSession from "../modules/instance/components/instances/InstanceRemoteSession";
import InstanceRemoteSessionHistory from "../modules/instance/components/instances/InstanceRemoteSessionHistory";
import Day2FeatureSelectionPage from "../modules/new-first-run/components/Day2FeatureSelectionPage";
import NewFirstRun from "../modules/new-first-run/components/NewFirstRun";
import SetupMFA from "../modules/users-roles/components/mfa/SetupMFA";
import { getLoginLink } from "../helpers/auth-partner-helper-functions";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { captchaSiteKey_v3 } from "../config/service";
const LayoutComponent = lazy(
  () => import("../components/Layout" /* webpackChunkName: "layout" */)
);

function RequireAuth({ children, redirectTo = "/login" }) {
  let isUserLoggedIn = getLoggedInUserAccessToken();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  if (!isUserLoggedIn) {
    dispatch(logoutUser());
    return <Navigate to={redirectTo} replace />;
  }
  return children;
}

const history = createBrowserHistory();
const accessType = localStorage.getItem(CUSTOMER_ROLE_TYPE);

const initialPage = getInitialPage(accessType);
const isDemoMode = config.demoApp ? config.demoApp.trim() === "true" : false;

const capturePerfomanceLoadAnalytics = (data) => {
  analytics.track({
    eventName: `${PERFORMANCE_EVENT}_${data.name}`,
    additionalProps: data,
  });
};

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstRender: true,
      isUserLoggedIn: getLoggedInUserAccessToken(),
      isAnalyticsInitialized: false,
      fcpData: null,
      lcpData: null,
    };
  }

  componentDidMount() {
    const isFirstRunUrl = history.location.pathname === APP_FIRST_RUN;
    if (this.state.isUserLoggedIn && isFirstRunUrl) {
      this.props.getUserData();
      this.props.getRegions();
      this.props.getLoggedInUserDetails();
      this.props.getCustomerPreference();
    }
    onFCP((data) => this.setState({ fcpData: data }));
    onLCP((data) => this.setState({ lcpData: data }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId && prevProps.userId !== this.props.userId) {
      analytics.identify(this.props.userId);
      this.setState({ isAnalyticsInitialized: true });
    }

    if (this.state.isAnalyticsInitialized) {
      if (this.state.fcpData) {
        capturePerfomanceLoadAnalytics(this.state.fcpData);
        this.setState({ fcpData: null });
      }
      if (this.state.lcpData) {
        capturePerfomanceLoadAnalytics(this.state.lcpData);
        this.setState({ lcpData: null });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.refreshPage) {
      window.location.reload();
    }
  }

  render() {
    const isFirstRunUrl = history.location.pathname === APP_FIRST_RUN;
    const { isFirstRender, isUserLoggedIn } = this.state;
    const {
      filteredUserData,
      filteredUserDataLoaded,
      subscriptionDetails,
      customerPreferencesFetched,
      accountList,
      azureSubscriptionsList,
    } = this.props;
    if (
      isFirstRender &&
      isUserLoggedIn &&
      filteredUserDataLoaded &&
      filteredUserData !== null &&
      subscriptionDetails !== null &&
      customerPreferencesFetched
    ) {
      this.setState({ isFirstRender: false });

      if (
        isFirstRunUrl &&
        filteredUserData &&
        (isArrayWithLength(accountList) ||
          (!isArrayWithLength(accountList) &&
            isArrayWithLength(filteredUserData.Departments) &&
            filteredUserData.Departments.length > 1) ||
          isArrayWithLength(azureSubscriptionsList)) &&
        !isDemoMode
      ) {
        history.push(initialPage);
      }
    }

    if (
      isUserLoggedIn &&
      isFirstRunUrl &&
      isFirstRender &&
      !customerPreferencesFetched
    ) {
      return (
        <div className="loading">
          <i className="fa fa-spin fa-spinner" />
        </div>
      );
    }
    const loginLink = getLoginLink();
    return (
      <React.Fragment>
        <Helmet>
          <title>MontyCloud DAY2™</title>
        </Helmet>
        <BrowserRouter>
          <Suspense fallback={<MCLoader />}>
            <RouteChangeDetector>
              <Routes>
                <Route
                  path="/app/all-resources/compute/server/:instanceId/remote-session/:sessionId"
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <InstanceRemoteSessionHistory
                        dispatch={this.props.dispatch}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/app/all-resources/compute/server/:instanceId/remote-session"
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <InstanceRemoteSession dispatch={this.props.dispatch} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/app/first-run"
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <NewFirstRun />
                    </RequireAuth>
                  }
                />
                <Route
                  path={FEATURE_SELECTION}
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <Day2FeatureSelectionPage
                        dispatch={this.props.dispatch}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path={SETUP_MFA_PATH}
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <SetupMFA dispatch={this.props.dispatch} />
                    </RequireAuth>
                  }
                />
                <Route path="/" element={<Navigate to={initialPage} />} />
                <Route path="/app" element={<Navigate to={initialPage} />} />
                <Route
                  path="/app/*"
                  element={
                    <RequireAuth redirectTo={loginLink}>
                      <LayoutComponent dispatch={this.props.dispatch} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/documentation"
                  element={
                    <Navigate to="/documentation/getting-started/overview" />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey_v3}>
                      <Login />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route path="/verify-mfa" element={<VerifyMFA />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/rm-signup" element={<SignUpRiverMeadow />} />
                <Route path="/verify" element={<VerifyEmail />} />
                <Route
                  path="/forgot-password"
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey_v3}>
                      <ForgotPassword />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path="/change-password"
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey_v3}>
                      <ChangePassword />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey_v3}>
                      <ResetPassword />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route path="*" element={<Navigate to={initialPage} />} />
              </Routes>
            </RouteChangeDetector>
          </Suspense>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const RouteChangeDetector = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const url = `${location.pathname}${location.search}`;
    analytics.trackPage(url);
  }, [location]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (loading) {
      setProgress(30); // Start loading
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 90) {
            clearInterval(interval);
            return oldProgress; // Slow down as it approaches 90%
          }
          return oldProgress + 10;
        });
      }, 300);
      return () => clearInterval(interval);
    } else {
      setProgress(100); // Finish loading
      // Optionally reset progress after a delay
      const timeout = setTimeout(() => setProgress(0), 400); // Wait for animation
      return () => clearTimeout(timeout);
    }
  }, [loading]);

  useEffect(() => {
    // Start loading
    setLoading(true);
    // Simulate loading for demonstration, ideally wait for actual page load
    const timer = setTimeout(() => setLoading(false), 1500); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, [location.pathname]); // Triggered on path change

  return (
    <>
      {loading && (
        <Progress
          value={progress}
          className="top-loader-success"
          id="top-loader-success"
        />
      )}
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.tokenReducer.isAuthenticated,
  isMFAEnabled: state.auth.isMFAEnabled,
  isMFAConfigured: state.auth.isMFAConfigured,
  refreshPage: state.auth.refreshPage,
  organizations: state.globalConfig.organizations,
  organizationsLoaded: state.globalConfig.organizationsLoaded,
  filteredUserData: state.globalConfig.filteredUserData,
  filteredUserDataLoaded: state.globalConfig.filteredUserDataLoaded,
  subscriptionDetails: state.auth.subscriptionDetails,
  customerPreferencesFetched: state.newFirstRun.customerPreferencesFetched,
  accountList: state.accounts.accountList,
  azureSubscriptionsList: state.accounts.azureSubscriptionsList,
  userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(getUserData()),
    getRegions: () => dispatch(getRegions()),
    getAllOrganizations: () => dispatch(getAllOrganizations()),
    getLoggedInUserDetails: () => dispatch(getLoggedInUserDetails()),
    getCustomerPreference: () => dispatch(getCustomerPreference()),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
