import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import s from "./Signup.module.scss";
import classnames from "classnames";
import { useTranslation, Trans } from "react-i18next";
import {
  montyCloudWebsiteLink,
  riverMeadowWebsiteLink,
  purchasePlanTermsLink,
} from "../../../../utils/app-links";
import SignupForm from "./SignupForm";
import { Link } from "react-router-dom";
import { customers, signUpServerManagementContentItems } from "./utils";
import { isArrayWithLength } from "../../../../utils/array-methods";
import { withRouter } from "@hocs/withRouter";
import classNames from "classnames";

const SignupV1 = ({ location }) => {
  const { t } = useTranslation(["signupForm", "signupLayoutServerManagement"]);

  return (
    <div className="auth-container signup">
      <Row className="bs4-p-3">
        <Col xl={6} xs={6}>
          <a
            href={montyCloudWebsiteLink}
            target="_blank"
            rel="noopener noreferrer"
            className={s.MCLogo}
          >
            <img
              loading="lazy"
              className={s.logo}
              src="/images/DAY2Logo-NoBg.png"
              alt="MontyCloud"
            />
          </a>
        </Col>

        <Col xl={6} xs={6} className="bs4-text-right">
          <h5 className={classNames(s.HeaderLogin, "breadcrumb-link")}>
            <Link to="/login">{t("loginLink")}</Link>
          </h5>
        </Col>
      </Row>
      <div className="server-bg">
        <Row className="row-transparent-bg bs4-p-0">
          <Col xl={12}>
            <div
              className={classnames(
                "bs4-container wrapper hero no-padding",
                s.ContainerPadding,
                s.BannerHeight
              )}
            >
              <Row>
                <Col xl={7} xs={12}>
                  <h1 className={s.ContainerHeading}>
                    {t("signupLayoutServerManagement:pageHeading")}
                  </h1>
                  <h4 className={s.ContainerSubHeading}>
                    {t("signupLayoutServerManagement:pageSubHeading")}
                  </h4>
                </Col>
                <Col xl={5} xs={12} className={s.FormContainer}>
                  <SignupForm location={location} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className={s.ListContainer}>
        <Row>
          <Col xl={12}>
            <div
              className={classnames(
                "bs4-container wrapper hero no-padding",
                s.ContainerPadding
              )}
            >
              <Row>
                <Col xl={7} xs={12}>
                  <div className="container">
                    <Row>
                      <Col xl={3} xs={12}>
                        <a
                          href={riverMeadowWebsiteLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            loading="lazy"
                            className={classnames(s.logoLarge, "bs4-mb-4")}
                            src="/images/rivermeadowlogo.svg"
                            alt="MontyCloud"
                          />
                        </a>
                      </Col>
                      <Col xl={9} xs={12} className={s.bannerTitle}>
                        {t("signupLayoutServerManagement:bannerTitle")}
                      </Col>
                    </Row>
                    <div className="bs4-pt-3 bs4-pb-4">
                      <div className={s.ListTopHeading}>
                        {t("signupLayoutServerManagement:pageListHeading")}
                      </div>
                    </div>
                    {isArrayWithLength(signUpServerManagementContentItems) &&
                      signUpServerManagementContentItems.map((item) => {
                        return (
                          <div
                            className={classnames("bs4-pt-4 pb-0", s.ListItem)}
                            key={item.key}
                          >
                            <div>
                              <div className={s.ListImage}>
                                <i
                                  className={classnames(item.icon, s.ListIcon)}
                                />
                              </div>
                              <div className={s.ListItemText}>
                                <div className={s.ListItemHeading}>
                                  {item.heading}
                                </div>
                                <div>{item.description}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="bs4-pt-4 bs4-pb-4">
                      <div className={s.ListTopHeading}>
                        <Trans
                          i18nKey="signupLayoutServerManagement:pageListFooterContent"
                          components={[<span className={s.HighlightMC}></span>]}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={5} xs={12}></Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} className="bs4-text-center mb-5">
            {isArrayWithLength(customers) &&
              customers.map((customer) => {
                return (
                  <a
                    key={customer.key}
                    href={customer.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      loading="lazy"
                      className={s.CustomerLogo}
                      src={"/images/customers/" + customer.icon}
                      alt={customer.key}
                    />
                  </a>
                );
              })}
          </Col>
          <Col xl={12} className="mt-5 bs4-text-center">
            <p className="bs4-text-center">
              ©&nbsp;{moment().format("YYYY")}&nbsp;{t("MCTitle")}
              &nbsp;|&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={purchasePlanTermsLink}
              >
                {t("link.T&C")}
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(SignupV1);
