import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../config/customBaseQuery";
import {
  COST_DASHBOARD_LOOKUP,
  AWS_COST_OPTIMIZATION_BASE_URL,
  AWS_COST_OPTIMIZATION_OVERVIEW_SUMMARY,
  AZURE_SPEND_VS_POTENTIAL_SAVINGS_CHART,
  TOP_5_OPPORTUNITIES,
  AWS_OPTIMIZATION_FINDINGS_BREAKDOWN,
  OPPORTUNITIES_SUMMARY,
  AWS_COST_OPTIMIZATION_COST_OPPORTUNITIES,
  CUSTOMER_ACCOUNT_COH_SETUP,
} from "../../../../config/service";
import { WEB_REQUEST_TYPE } from "../../../../helpers/apiService";
import { CLOUDPROVIDERS } from "../../../../utils/app-constants.json";
import { isArrayWithLength } from "../../../../utils/array-methods";

export const awsCostOptimizationOverviewApi = createApi({
  reducerPath: "awsCostOptimizationOverview",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAWSCostOptimizationAccounts: builder.query({
      query: () => {
        return {
          url: `${COST_DASHBOARD_LOOKUP}?CloudProvider=${CLOUDPROVIDERS.AWS}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      transformResponse: (response) => {
        return Object.entries(response.Accounts ?? {}).map(([key, value]) => ({
          accountNumber: key,
          accountName: value,
        }));
      },
    }),
    getAWSCostOptimizationSummary: builder.query({
      query: ({ accountNumbers, tenure }) => {
        const encodedAccountNumbers =
          isArrayWithLength(accountNumbers) &&
          encodeURIComponent(JSON.stringify(accountNumbers));
        return {
          url: `${AWS_COST_OPTIMIZATION_BASE_URL}${AWS_COST_OPTIMIZATION_OVERVIEW_SUMMARY}?Tenure=${tenure}${encodedAccountNumbers ? `&AccountNumbers=${encodedAccountNumbers}` : ""}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),

    getAWSSpendVsPotentialSavings: builder.query({
      query: ({ accountNumbers, tenure }) => {
        const encodedAccountNumbers =
          isArrayWithLength(accountNumbers) &&
          encodeURIComponent(JSON.stringify(accountNumbers));
        return {
          url: `${AWS_COST_OPTIMIZATION_BASE_URL}${AZURE_SPEND_VS_POTENTIAL_SAVINGS_CHART}?Tenure=${tenure}${encodedAccountNumbers ? `&AccountNumbers=${encodedAccountNumbers}` : ""}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    getAWSOptimizationFindingsBreakdown: builder.query({
      query: ({ accountNumbers, tenure }) => {
        const encodedAccountNumbers =
          isArrayWithLength(accountNumbers) &&
          encodeURIComponent(JSON.stringify(accountNumbers));
        return {
          url: `${AWS_COST_OPTIMIZATION_BASE_URL}${AWS_OPTIMIZATION_FINDINGS_BREAKDOWN}?Tenure=${tenure}${encodedAccountNumbers ? `&AccountNumbers=${encodedAccountNumbers}` : ""}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    getAWSTop5Opportunities: builder.query({
      query: ({ accountNumbers, tenure, opportunityType }) => {
        const encodedAccountNumbers =
          isArrayWithLength(accountNumbers) &&
          encodeURIComponent(JSON.stringify(accountNumbers));
        return {
          url: `${AWS_COST_OPTIMIZATION_BASE_URL}${TOP_5_OPPORTUNITIES.replace("{opportunityType}", opportunityType)}?Tenure=${tenure}&Count=5${encodedAccountNumbers ? `&AccountNumbers=${encodedAccountNumbers}` : ""}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    getAWSOpportunitiesSummary: builder.query({
      query: ({
        opportunityType,
        accountNumbers,
        serviceType,
        commitmentTerm,
        paymentOption,
        rollbackPossible,
      }) => {
        const encodedAccountNumbers =
          isArrayWithLength(accountNumbers) &&
          encodeURIComponent(JSON.stringify(accountNumbers));

        return {
          url: `${AWS_COST_OPTIMIZATION_BASE_URL}${OPPORTUNITIES_SUMMARY.replace("{OpportunityType}", opportunityType)}?ServiceType=${serviceType}${encodedAccountNumbers ? `&AccountNumbers=${encodedAccountNumbers}` : ""}${commitmentTerm ? `&CommitmentTerm=${commitmentTerm}` : ""}${paymentOption ? `&paymentOption=${paymentOption}` : ""}${rollbackPossible ? `&rollbackPossible=${rollbackPossible}` : ""}`,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) => ({
        data: response,
        serviceType: arg.serviceType,
      }),
    }),
    getAwsCostOptimizationOpportunitiesOptions: builder.query({
      query: ({
        FilterType,
        OpportunityType,
        Limit = 5,
        Offset = 0,
        KeywordSearch = "",
        AccountNumbers = [],
      }) => {
        // Construct the query string conditionally
        let queryParams = `?Limit=${Limit}&Offset=${Offset}`;

        if (isArrayWithLength(AccountNumbers)) {
          queryParams += `&AccountNumbers=${encodeURIComponent(JSON.stringify(AccountNumbers))}`;
        }

        // Only include KeywordSearch if it's truthy (non-empty)
        if (KeywordSearch) {
          queryParams += `&KeywordSearch=${encodeURIComponent(JSON.stringify([{ Field: FilterType, Value: KeywordSearch }]))}`;
        }

        return {
          url: `${AWS_COST_OPTIMIZATION_COST_OPPORTUNITIES}/${OpportunityType}/filters/ResourceArn${queryParams}`,
          method: "GET",
        };
      },
    }),
    cohSetupRetry: builder.mutation({
      query({ accountNumber }) {
        return {
          url: `${CUSTOMER_ACCOUNT_COH_SETUP}`,
          method: WEB_REQUEST_TYPE.POST,
          body: {
            AccountNumber: accountNumber,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAWSCostOptimizationAccountsQuery,
  useGetAWSCostOptimizationSummaryQuery,
  useGetAWSSpendVsPotentialSavingsQuery,
  useGetAWSOptimizationFindingsBreakdownQuery,
  useGetAWSTop5OpportunitiesQuery,
  useGetAWSOpportunitiesSummaryQuery,
  useGetAWSCostOptimizationOpportunitiesOptionsQuery,
  useCohSetupRetryMutation,
} = awsCostOptimizationOverviewApi;
