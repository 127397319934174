import {
  GET_ASSESSMENT_QUESTIONS_FAILURE,
  GET_ASSESSMENT_QUESTIONS_REQUEST,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_QUESTION_DETAILS_FAILURE,
  GET_ASSESSMENT_QUESTION_DETAILS_REQUEST,
  GET_ASSESSMENT_QUESTION_DETAILS_SUCCESS,
  SAVE_ASSESSMENT_QUESTION_REQUEST,
  SAVE_ASSESSMENT_QUESTION_FAILURE,
  SAVE_ASSESSMENT_QUESTION_SUCCESS,
  GET_ASSESSMENT_QUESTION_REASONS_SUCCESS,
  CLEAR_ASSESSMENT_REVIEW,
  SELECT_BEST_PRACTICE_CHOICE,
  SELECT_BEST_PRACTICE_CHOICE_REASON,
  SET_BEST_PRACTICE_CHOICE_ADDITIONAL_REASON,
  CLEAR_BEST_PRACTICES,
  INITIALIZE_BEST_PRACTICE_CHOICE,
  UNSELECT_BEST_PRACTICE_CHOICE,
  CLEAR_ASSESSMENT_QUESTIONS,
} from "../actionTypes/assessments";

const initialState = {
  fetchingAssessmentQuestions: false,
  fetchingAssessmentQuestionsError: false,
  assessmentQuestions: [],
  fetchingAssessmentQuestionDetails: false,
  fetchingAssessmentQuestionDetailsError: false,
  assessmentQuestionDetails: null,
  savingAssessmentQuestion: false,
  savedAssessmentQuestion: false,
  assessmentQuestionReasons: [],
  bestPracticeChoicesSelection: {},
  bestPracticeChoicesReason: {},
  bestPracticeChoicesAdditionalReason: {},
};

const assessmentReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENT_QUESTIONS_REQUEST: {
      return {
        ...state,
        fetchingAssessmentQuestions: true,
        fetchingAssessmentQuestionsError: false,
      };
    }
    case GET_ASSESSMENT_QUESTIONS_SUCCESS: {
      return {
        ...state,
        fetchingAssessmentQuestions: false,
        assessmentQuestions: action.payload,
      };
    }
    case GET_ASSESSMENT_QUESTIONS_FAILURE: {
      return {
        ...state,
        fetchingAssessmentQuestions: false,
        fetchingAssessmentQuestionsError: true,
      };
    }
    case CLEAR_ASSESSMENT_QUESTIONS: {
      return {
        ...state,
        assessmentQuestions: initialState.assessmentQuestions,
      };
    }
    case GET_ASSESSMENT_QUESTION_DETAILS_REQUEST: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: true,
        fetchingAssessmentQuestionDetailsError: false,
      };
    }
    case GET_ASSESSMENT_QUESTION_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: false,
        assessmentQuestionDetails: action.payload,
      };
    }
    case GET_ASSESSMENT_QUESTION_DETAILS_FAILURE: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: false,
        fetchingAssessmentQuestionDetailsError: true,
      };
    }
    case SAVE_ASSESSMENT_QUESTION_REQUEST: {
      return {
        ...state,
        savingAssessmentQuestion: true,
        savedAssessmentQuestion: false,
      };
    }
    case SAVE_ASSESSMENT_QUESTION_SUCCESS: {
      return {
        ...state,
        savingAssessmentQuestion: false,
        savedAssessmentQuestion: true,
      };
    }
    case SAVE_ASSESSMENT_QUESTION_FAILURE: {
      return {
        ...state,
        savingAssessmentQuestion: false,
        savedAssessmentQuestion: false,
      };
    }
    case GET_ASSESSMENT_QUESTION_REASONS_SUCCESS: {
      return {
        ...state,
        assessmentQuestionReasons: action.payload,
      };
    }
    case CLEAR_ASSESSMENT_REVIEW: {
      return {
        ...state,
        assessmentQuestions: initialState.assessmentQuestions,
        assessmentQuestionDetails: initialState.assessmentQuestionDetails,
      };
    }
    case SELECT_BEST_PRACTICE_CHOICE: {
      return {
        ...state,
        bestPracticeChoicesSelection: {
          ...state.bestPracticeChoicesSelection,
          [action.payload]: true,
        },
        bestPracticeChoicesAdditionalReason: {
          ...state.bestPracticeChoicesAdditionalReason,
          [action.payload]: "",
        },
      };
    }
    case UNSELECT_BEST_PRACTICE_CHOICE: {
      //deleting the choice selection
      const bestPracticeChoicesSelection = {
        ...state.bestPracticeChoicesSelection,
      };
      delete bestPracticeChoicesSelection[action.payload];

      //deleting the choice selection reason
      const bestPracticeChoicesReason = {
        ...state.bestPracticeChoicesReason,
      };
      delete bestPracticeChoicesReason[action.payload];

      //deleting the choices additional reason
      const bestPracticeChoicesAdditionalReason = {
        ...state.bestPracticeChoicesAdditionalReason,
      };
      delete bestPracticeChoicesAdditionalReason[action.payload];
      return {
        ...state,
        bestPracticeChoicesSelection: {
          ...bestPracticeChoicesSelection,
        },
        bestPracticeChoicesReason: {
          ...bestPracticeChoicesReason,
        },
        bestPracticeChoicesAdditionalReason: {
          ...bestPracticeChoicesAdditionalReason,
        },
      };
    }
    case SELECT_BEST_PRACTICE_CHOICE_REASON: {
      return {
        ...state,
        bestPracticeChoicesReason: {
          ...state.bestPracticeChoicesReason,
          [action.payload.choiceId]: action.payload.reason,
        },
      };
    }
    case SET_BEST_PRACTICE_CHOICE_ADDITIONAL_REASON: {
      return {
        ...state,
        bestPracticeChoicesAdditionalReason: {
          ...state.bestPracticeChoicesAdditionalReason,
          [action.payload.choiceId]: action.payload.additionalReason,
        },
      };
    }
    case INITIALIZE_BEST_PRACTICE_CHOICE: {
      return {
        ...state,
        bestPracticeChoicesSelection: {
          ...state.bestPracticeChoicesSelection,
          [action.payload.choiceId]:
            !state.bestPracticeChoicesSelection[action.payload.choiceId],
        },
        bestPracticeChoicesReason: {
          ...state.bestPracticeChoicesReason,
          [action.payload.choiceId]: action.payload.reason,
        },
        bestPracticeChoicesAdditionalReason: {
          ...state.bestPracticeChoicesAdditionalReason,
          [action.payload.choiceId]: action.payload.additionalReason,
        },
      };
    }
    case CLEAR_BEST_PRACTICES: {
      return {
        ...state,
        bestPracticeChoicesSelection: initialState.bestPracticeChoicesSelection,
        bestPracticeChoicesReason: initialState.bestPracticeChoicesReason,
        bestPracticeChoicesAdditionalReason:
          initialState.bestPracticeChoicesAdditionalReason,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assessmentReviewReducer;
