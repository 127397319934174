import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WEB_REQUEST_TYPE, getApiService } from "../../../helpers/apiService";
import { MANAGEMENT_REPORTS_EXPORTS, FILE } from "../../../config/service";
import { isArrayWithLength } from "../../../utils/array-methods";

export const getManagementOpportunitiesExportsApi =
  ({ categoryName, tenantIds = [] }) =>
  ({ limit, offset = 0, sortModel, filterModel = [] }) => {
    const baseFilters = { ReportType: "Opportunities", Category: categoryName };
    if (isArrayWithLength(tenantIds)) {
      baseFilters["OrganizationIds"] = tenantIds;
    }
    const filters = encodeURIComponent(
      JSON.stringify({
        ...baseFilters,
        ...(isArrayWithLength(filterModel) && { KeywordSearch: filterModel }),
      })
    );
    let params = `?Limit=${limit}&Offset=${offset}&Filters=${filters}`;
    if (sortModel?.SortBy) {
      const { SortOrder, SortBy } = sortModel;
      params = `${params}&SortBy=${SortBy}&SortOrder=${SortOrder}`;
    }
    return getApiService(
      WEB_REQUEST_TYPE.GET,
      `${MANAGEMENT_REPORTS_EXPORTS}${params}`
    );
  };

export const managementOpportunitiesAPI = createApi({
  reducerPath: "managementOpportunities",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    downloadManagementOpportunity: builder.query({
      query({ ReportId, FileName, action = "download" }) {
        const enCodedParams = encodeURIComponent(JSON.stringify({ FileName }));
        return {
          url: `${MANAGEMENT_REPORTS_EXPORTS}/${ReportId}${FILE}?Filters=${enCodedParams}&Action=${action}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    triggerOpportunitiesReport: builder.mutation({
      query: ({ orgId }) => {
        return {
          url: `/copilot/reports?OrganizationId=${orgId}`,
          method: WEB_REQUEST_TYPE.POST,
        };
      },
    }),
  }),
});

export const {
  useLazyDownloadManagementOpportunityQuery,
  useTriggerOpportunitiesReportMutation,
} = managementOpportunitiesAPI;
