export const ERROR_EVENT = {
  UI: "Error_UI",
  API: "Error_Api",
};

export const PERFORMANCE_EVENT = "Performance";

export const RESET_PASSWORD_EVENT_CAPTCHA_ERROR =
  "ResetPassword.CaptchaError.Invalid";
export const FORGOT_PASSWORD_EVENT_CAPTCHA_ERROR =
  "ForgotPassword.CaptchaError.Invalid";
export const CHANGE_PASSWORD_EVENT_CAPTCHA_ERROR =
  "ChangePassword.CaptchaError.Invalid";
export const LOGIN_EVENT_CAPTCHA_ERROR = "Login.CaptchaError.Invalid";
