import { createContext } from "react";
import { CLOUDPROVIDERS } from "../../utils/app-constants.json";

export const CloudProviderSwitcherContext = createContext(CLOUDPROVIDERS.AWS);

export const SIDEBAR_DATA_TEST_IDS = {
  COLLAPSE: "collapse",
  EXPAND: "expand",
  SETTINGS_GO_BACK: "settings-go-back",
  GO_HOME: "Go Home",
  CLOUDSWITCHER: "cloudswitcher",
  CLOUDSWITCHER_AWS: "cloudswitcher-aws",
  CLOUDSWITCHER_AZURE: "cloudswitcher-azure",
  SIDEBAR_SETTINGS_GENERAL: "sidebar-settings-general",
  SIDEBAR_SETTINGS_ACCOUNTS: "sidebar-settings-accounts",
  SIDEBAR_SETTINGS_USERS: "sidebar-settings-users",
  SIDEBAR_SETTINGS_FEDERATED_ACCESS: "sidebar-settings-federated-access",
  SIDEBAR_SETTINGS_SSO: "sidebar-settings-sso",
  SIDEBAR_SETTINGS_DAY2BOTS: "sidebar-settings-day2bots",
  SIDEBAR_SETTINGS_CONNECTORS: "sidebar-settings-connectors",
  SIDEBAR_AWS_HOME: "sidebar-aws-home",
  SIDEBAR_AWS_PROJECTS: "sidebar-aws-projects",
  SIDEBAR_AWS_WELL_ARCHITECTED: "sidebar-aws-well-architected",
  SIDEBAR_AWS_FOUNDATIONAL_BEST_PRACTICES:
    "sidebar-aws-foundational-best-practices",
  SIDEBAR_AWS_SERVERS: "sidebar-aws-servers",
  SIDEBAR_AWS_ALL_RESOURCES: "sidebar-aws-all-resources",
  SIDEBAR_AWS_TAGS: "sidebar-aws-tags",
  SIDEBAR_AWS_POLICIES: "sidebar-aws-policies",
  SIDEBAR_AWS_SERVICE_CATALOG: "sidebar-aws-service-catalog",
  SIDEBAR_AWS_COST: "sidebar-aws-cost",
  SIDEBAR_AWS_OPTIMIZATION: "sidebar-aws-optimization",
  SIDEBAR_AWS_SECURITY: "sidebar-aws-security",
  SIDEBAR_AWS_COMPLIANCE: "sidebar-aws-compliance",
  SIDEBAR_AWS_AUTOMATION: "sidebar-aws-automation",
  SIDEBAR_AWS_REPORTS: "sidebar-aws-reports",
  SIDEBAR_AZURE_HOME: "sidebar-azure-home",
  SIDEBAR_AZURE_WELL_ARCHITECTED: "sidebar-azure-well-architected",
  SIDEBAR_AZURE_FOUNDATIONAL_BEST_PRACTICES:
    "sidebar-azure-foundational-best-practices",
  SIDEBAR_AZURE_ALL_RESOURCES: "sidebar-azure-all-resources",
  SIDEBAR_AZURE_COST: "sidebar-azure-cost",
  SIDEBAR_AZURE_OPTIMIZATION: "sidebar-azure-optimization",
  SIDEBAR_AZURE_OPTIMIZATION_OVERVIEW: "sidebar-azure-optimization-overview",
  SIDEBAR_AZURE_OPTIMIZATION_SAVINGS_OPPORTUNITIES:
    "sidebar-azure-optimization-savings-opportunities",
  SIDEBAR_AZURE_OPTIMIZATION_RESERVATION_OPPORTUNITIES:
    "sidebar-azure-optimization-reservation-opportunities",
  SIDEBAR_AZURE_SECURITY: "sidebar-azure-security",
  SIDEBAR_AZURE_COMPLIANCE: "sidebar-azure-compliance",
  SIDEBAR_AZURE_AUTOMATION: "sidebar-azure-automation",
  SIDEBAR_AZURE_REPORTS: "sidebar-azure-reports",
  SIDEBAR_MANAGEMENT_TENANTS: "sidebar-management-tenants",
  SIDEBAR_MANAGEMENT_BILLING: "sidebar-management-billing",
  SIDEBAR_MANAGEMENT_OPPORTUNITIES: "sidebar-management-opportunities",
  SIDEBAR_MANAGEMENT_REPORTS: "sidebar-management-reports",
};
