import React from "react";
import styles from "./NewFirstRun.module.scss";
import classNames from "classnames";
import AccountTypeSelector from "../../account/components/account/AccountTypeSelector";

const ConfigureAccountType = ({
  previousStep,
  nextStep,
  disablePreviousStep = false,
}) => {
  const CONNECT_ACCOUNT_IMAGE =
    "/images/dashboard/securely-connect-account.png";
  return (
    <div
      className={classNames(
        styles.welcomeDivLarge,
        styles.ContentBackground,
        styles.ConnectAccountContainer,
        "d-flex"
      )}
    >
      <div className={classNames("bs4-text-center", styles.cloudProviderImage)}>
        <img loading="lazy"
          src={CONNECT_ACCOUNT_IMAGE}
          alt="day2"
          className={styles.ConnectAccountImage}
        />
      </div>
      <div className={classNames(styles.accountTypeContent)}>
        <AccountTypeSelector
          isFre={true}
          previousStep={previousStep}
          nextStep={nextStep}
          disablePreviousStep={disablePreviousStep}
        />
      </div>
    </div>
  );
};

export default ConfigureAccountType;
