import classNames from "classnames";
import { useState } from "react";
import { COPILOT_ACCESS_REQUEST } from "../../../config/service";
import { getApiService, WEB_REQUEST_TYPE } from "../../../helpers/apiService";
import styles from "./CloudOpsCopilotFeatureStep.module.scss";

import { useTranslation } from "react-i18next";
import { MC_TERMS_AND_CONDITIONS } from "../../../utils/app-links";

const CloudOpsCopilotFeatureStep = ({ parentNext }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [isRequestingAccessSuccess, setIsRequestingAccessSuccess] =
    useState(false);
    const { t } = useTranslation(["marvin"]);
  const onRequestAccess = () => {
    setIsRequestingAccess(true);

    getApiService(WEB_REQUEST_TYPE.POST, COPILOT_ACCESS_REQUEST)
    .then((data) => {
      setIsRequestingAccessSuccess(true);
    })
    .finally(() => {
      setIsRequestingAccess(false);
      parentNext && parentNext();
    });
  };

  return (
    <div
      className={classNames(
        styles.welcomeDivLarge,
        styles.ContentBackground,
        styles.ConnectAccountContainer
      )}
    >
      <div className="d-flex w-100 px-2">
        <div className="w-50 d-flex flex-column px-4">
          <div>
            <img
              src={"/images/cloudops-copilot-64.png"}
              alt={t('cloudOpsCopilotFeatureStep.alt.copilotIcon')}
              className={styles.copilotIcon}
            />
          </div>
          <div className={styles.title}>
            {t('cloudOpsCopilotFeatureStep.title')}
          </div>
          <div className="d-flex flex-column gap-2 mt-4">
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-Optimization.png"
                className={styles.copilotIcon}
              />
              <div className="d-flex flex-column">
                <div className={styles.optimizationTitle}>
                  {t('cloudOpsCopilotFeatureStep.optimization.title')}
                </div>
                <p className={styles.optimizationDescription}>
                  {t('cloudOpsCopilotFeatureStep.optimization.description')}
                </p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-Reports.png"
                className={styles.copilotIcon}
              />
              <div className="d-flex flex-column">
                <div className={styles.reportsTitle}>
                  {t('cloudOpsCopilotFeatureStep.reports.title')}
                </div>
                <p className={styles.reportsDescription}>
                  {t('cloudOpsCopilotFeatureStep.reports.description')}
                </p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <img
                src="/images/fre/Copilot-MSP-Leads.png"
                className={styles.copilotIcon}
              />
              <div className="d-flex flex-column">
                <div className={styles.mspLeadsTitle}>
                  {t('cloudOpsCopilotFeatureStep.mspLeads.title')}
                </div>
                <p className={styles.mspLeadsDescription}>
                  {t('cloudOpsCopilotFeatureStep.mspLeads.description')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-50 d-flex flex-column px-4">
          <div className={styles.iframeContainer}>
            <iframe
              src={MC_TERMS_AND_CONDITIONS}
              className={styles.iframeContainer}
            />
          </div>
          <div
            onClick={() => setIsChecked(!isChecked)}
            className="d-flex gap-1 mt-4"
          >
            {isChecked ? (
              <img
                src={"/images/icons/checkbox-checked.svg"}
                alt={t('cloudOpsCopilotFeatureStep.alt.checked')}
                className={styles.checkedIcon}
              />
            ) : (
              <img
                src={"/images/icons/checkbox-unchecked.svg"}
                alt={t('cloudOpsCopilotFeatureStep.alt.unchecked')}
                className={styles.uncheckedIcon}
              />
            )}
            <div className={styles.termsCheckboxContainer}>
              {t('cloudOpsCopilotFeatureStep.agreement')}
            </div>
          </div>
          <div className="d-flex gap-4 mt-3 ">
            <button
              className={classNames(
                "w-50",
                styles.copilotButton,
                styles.tryLater
              )}
              disabled={isRequestingAccess || isRequestingAccessSuccess}
              onClick={() => {
                parentNext && parentNext();
              }}
            >
              {t('cloudOpsCopilotFeatureStep.button.skip')}
            </button>
            <button
              className={classNames("w-50", styles.copilotButton)}
              disabled={
                isRequestingAccess || isRequestingAccessSuccess || !isChecked
              }
              onClick={onRequestAccess}
              id="tryNow"
            >
              {t('cloudOpsCopilotFeatureStep.button.enable')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CloudOpsCopilotFeatureStep };
