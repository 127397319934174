import React, { useState, useEffect } from "react";
import {
  mapFieldsArrayWithValues,
  reduceFieldsArrayToParams,
} from "../../../../utils/array-methods";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CommonFormRenderer from "../../../commons/components/common-form-renderer/CommonFormRenderer";
import { verifyMFA } from "../../../users-roles/actions/mfa";
import MCButton from "../../../../components/MCButton";
import {
  clearLoginScreen,
  clearLoginState,
  dispatchMFARequired,
} from "../../actions/auth";

const MFALogin = ({ mfaData }) => {
  const { t } = useTranslation(["mfa", "common"]);

  const fields = [
    {
      type: "text",
      placeholder: t("common:enterKey", {
        label: t("common:code").toLowerCase(),
      }),
      required: true,
      key: "mfa-code",
      apiKey: "MFACode",
      value: "",
      id: "mfa-code",
      autoComplete: "off",
    },
  ];

  const dispatch = useDispatch();

  const [hasError, setHasError] = useState(true);
  const [updatedFields, setUpdatedFields] = useState([]);

  const { isVerifying, isVerified } = useSelector(({ mfaReducer }) => {
    const { verifyMFA } = mfaReducer;
    return {
      isVerifying: verifyMFA.isCreating,
      isVerified: verifyMFA.isCreated,
    };
  }, shallowEqual);

  const handleCancel = () => {
    dispatch(dispatchMFARequired());
    dispatch(clearLoginScreen());
    dispatch(clearLoginState());
  };

  useEffect(() => {
    if (isVerified) {
      dispatch(dispatchMFARequired());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified]);

  const handleUpdateFields = (values, hasError) => {
    const updatedFields = mapFieldsArrayWithValues(fields, values);
    setUpdatedFields(updatedFields);
    setHasError(hasError);
  };

  const handleSetupMFA = () => {
    const params = reduceFieldsArrayToParams(updatedFields);
    const updatedParams = {
      ...params,
      Session: mfaData.session,
      Username: mfaData.username,
    };
    dispatch(verifyMFA(updatedParams, mfaData.username));
  };

  return (
    <>
      <div>{t("enterCodeHelpText")}</div>
      <CommonFormRenderer
        fields={fields}
        handleUpdateFields={handleUpdateFields}
        onSubmit={handleSetupMFA}
        className="bs4-mt-3"
      />
      <div className="bs4-mt-4 bs4-text-right">
        <MCButton
          text={t("common:cancelLabel")}
          onClick={handleCancel}
          className="bs4-mr-2"
        />
        <MCButton
          disabled={hasError || isVerifying}
          text={
            isVerifying ? `${t("common:verifying")}...` : t("common:verify")
          }
          onClick={handleSetupMFA}
          color="mc-blue"
        />
      </div>
    </>
  );
};

export default MFALogin;
