import {
  SET_REPORT_SCOPE,
  REPORTS_SHOW_DATE_PICKER_FIELD,
  REPORTS_SHOW_YEAR_PICKER_FIELD,
  REPORTS_SET_SELECTED_DATE,
  REPORTS_SET_SELECTED_YEAR,
  REPORTS_SHOW_POLICY_PICKER_FIELD,
  REPORTS_SHOW_POLICY_PICKER_MODAL,
  REPORTS_SET_SELECTED_POLICY,
  REPORTS_SHOW_GROUP_PICKER_FIELD,
  REPORTS_SHOW_GROUP_PICKER_MODAL,
  REPORTS_SHOW_APP_PICKER_FIELD,
  REPORTS_SHOW_APP_PICKER_MODAL,
  REPORTS_SET_SELECTED_APP,
  REPORTS_SET_SELECTED_GROUP,
  RESET_REPORTS_CRITERIA,
  REPORTS_SET_SELECTED_DEPT,
  REPORTS_SHOW_DEPT_PICKER_MODAL,
  REPORTS_SET_SELECTED_MILESTONE,
  WAFR_REPORTS_SET_FILTERS,
  AWS_CO_REPORTS_SET_FILTERS
} from "../actionTypes/reportCriteria";

import moment from "moment";

const initialState = {
  showDatePicker: false,
  showYearPicker: false,
  showPolicyPicker: false,
  showPolicyPickerModal: false,
  showGroupPicker: false,
  showGroupPickerModal: false,
  showAppPicker: false,
  showDeptPickerModal: false,
  showAppPickerModal: false,
  selectedDate: new moment().format("YYYY-MM-DD"),
  selectedYear: new moment().format("YYYY"),
  selectedPolicy: null,
  selectedGroup: null,
  selectedApp: null,
  reportScope: null,
  selectedDept: null,
  selectedMilestone: null,
  selectedWafrReportsFilters: null,
};

const reportCriteriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_SHOW_DATE_PICKER_FIELD:
      return {
        ...state,
        showDatePicker: action.payload,
      };
    case REPORTS_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case REPORTS_SHOW_YEAR_PICKER_FIELD:
      return {
        ...state,
        showYearPicker: action.payload,
      };
    case REPORTS_SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case REPORTS_SHOW_POLICY_PICKER_FIELD:
      return {
        ...state,
        showPolicyPicker: action.payload,
      };
    case REPORTS_SHOW_POLICY_PICKER_MODAL:
      return {
        ...state,
        showPolicyPickerModal: action.payload,
      };
    case REPORTS_SET_SELECTED_POLICY:
      return {
        ...state,
        selectedPolicy: action.payload,
      };
    case REPORTS_SHOW_GROUP_PICKER_FIELD:
      return {
        ...state,
        showGroupPicker: action.payload,
      };
    case REPORTS_SHOW_GROUP_PICKER_MODAL:
      return {
        ...state,
        showGroupPickerModal: action.payload,
      };
    case REPORTS_SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    case REPORTS_SHOW_APP_PICKER_FIELD:
      return {
        ...state,
        showAppPicker: action.payload,
      };
    case REPORTS_SHOW_DEPT_PICKER_MODAL:
      return {
        ...state,
        showDeptPickerModal: action.payload,
      };
    case REPORTS_SHOW_APP_PICKER_MODAL:
      return {
        ...state,
        showAppPickerModal: action.payload,
      };
    case REPORTS_SET_SELECTED_APP:
      return {
        ...state,
        selectedApp: action.payload,
      };
    case REPORTS_SET_SELECTED_DEPT:
      return {
        ...state,
        selectedDept: action.payload,
      };
    case SET_REPORT_SCOPE:
      return {
        ...state,
        reportScope: action.payload,
      };
    case RESET_REPORTS_CRITERIA:
      return {
        ...state,
        showDatePicker: initialState.showDatePicker,
        showYearPicker: initialState.showYearPicker,
        showPolicyPicker: initialState.showPolicyPicker,
        showPolicyPickerModal: initialState.showPolicyPickerModal,
        showGroupPicker: initialState.showGroupPicker,
        showGroupPickerModal: initialState.showGroupPickerModal,
        showAppPicker: initialState.showAppPicker,
        showAppPickerModal: initialState.showAppPickerModal,
        selectedDate: initialState.selectedDate,
        selectedYear: initialState.selectedYear,
        selectedPolicy: initialState.selectedPolicy,
        selectedGroup: initialState.selectedGroup,
        selectedDept: initialState.selectedDept,
        showDeptPickerModal: initialState.showDeptPickerModal,
        selectedApp: initialState.selectedApp,
        reportScope: initialState.reportScope,
        selectedMilestone: initialState.selectedMilestone,
      };
    case REPORTS_SET_SELECTED_MILESTONE:
      return {
        ...state,
        selectedMilestone: action.payload,
      };
    case WAFR_REPORTS_SET_FILTERS:
      return {
        ...state,
        selectedWafrReportsFilters: action.payload,
      };
      case AWS_CO_REPORTS_SET_FILTERS:
      return {
        ...state,
        selectedAwsCoReportsFilter: action.payload,
      };
    default:
      return state;
  }
};

export default reportCriteriaReducer;
