import React from "react";
import { useTranslation } from "react-i18next";

import accountStyles from "../Account.module.scss";

const StackCreation = ({ handleStartOver }) => {
  const { t } = useTranslation("addAccount");

  return (
    <div className="bs4-text-center mt-5">
      <span className={accountStyles.loading}>
        <i className="fa fa-spin fa-spinner" />
      </span>
      <h5 className="bs4-mb-4 mt-5">{t("stackCreationMessagePart1")}</h5>
      <p className="mc-text-base-regular">{t("stackCreationMessagePart2")}</p>
      <p className="mc-text-base-regular">{t("stackCreationMessagePart3")}</p>
      <button
        className="btn orange-text btn-sm button-without-border mc-text-sm-regular"
        onClick={handleStartOver}
      >
        <i className="fa fa-repeat" /> {t("btn.startOver")}
      </button>
    </div>
  );
};

export default StackCreation;
