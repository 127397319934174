import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./index";
import { tenantCategoryAPI } from "../modules/tenant-category/apiService/tenant-category-api-service";
import { findingSummaryApi } from "../modules/assessments/apiService/assessment-findings-api-service";
import { milestoneFindingSummaryApi } from "../modules/assessments/apiService/milestone-findings-api-service";
import { wafrFindingsFiltersApi } from "../modules/assessments/apiService/findings-filters-api-service";
import { healthEventDetailsApi } from "../modules/alerts/awsHealthDashboard/services/eventDetails";
import { tenantAccountsApi } from "../modules/management-fbp/services/tenant-accounts-api-service";
import { healthEventConfigurationApi } from "../modules/management-fbp/services/health-event-configuration";
import { costTagKeysApi } from "../modules/cost/components/dashboard/ResourceExplorer/ResourceGroupByTag/apiService/cost-tag-keys-api-service";
import { costTagValuesApi } from "../modules/cost/components/dashboard/ResourceExplorer/ResourceGroupByTag/apiService/cost-tag-values-api-service";
import { assessmentListApi } from "../modules/assessments/apiService/assessment-list-api-service";
import { azureDashboardAPI } from "../modules/dashboard/components/azure-dashboard/apiService/azure-dashboard-api-service";
import { customLineItemsAPI } from "../modules/cost/abc/components/credits/services/customLineItems";
import { creditsAPI } from "../modules/cost/abc/components/credits/services/creditsApiServices";
import { federatedAccessApi } from "../modules/FederatedAccess/service/federated-access-api";
import { marketplaceChargesAPI } from "../modules/cost/abc/components/marketplace-charges/services/marketplaceChargesAPIServices";
import { managementReportsAPI } from "../modules/management-reports/services/managementReportsAPIServices";
import { mapHistoricalData } from "../modules/map-project/services/mapHistoricalData";
import { logoAPI } from "../modules/organizations-multi-tenancy/apiService/logo-api-service";
import { assessmentInsightsAPI } from "../modules/assessments/components/assessment-insights/apiService/assessment-insights-apiService";
import { supportChargesAPI } from "../modules/cost/abc/components/support-charges/services/supportChargesAPIServices";
import { azureOptimizationOverviewApi } from "../modules/cost-optimization/azure-cost-optimization/services/azure-optimization";
import { healthEventsAPI } from "../modules/alerts/awsHealthDashboard/services/healthEvents";
import { foundationalBestPracticeApi } from "../modules/assessments/apiService/foundational-best-practice-api-service";
import { managementOpportunitiesAPI } from "../modules/management-opportunities/services/managementOpportunitiesAPIServices";
import { portalTenantListApi } from "../modules/blueprints/services/share-tenant-api-service";
import { awsCostOptimizationOverviewApi } from "../modules/cost-optimization/aws-cost-optimization/services/aws-cost-optimization";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      tenantCategoryAPI.middleware,
      findingSummaryApi.middleware,
      milestoneFindingSummaryApi.middleware,
      wafrFindingsFiltersApi.middleware,
      healthEventDetailsApi.middleware,
      tenantAccountsApi.middleware,
      healthEventConfigurationApi.middleware,
      costTagKeysApi.middleware,
      costTagValuesApi.middleware,
      assessmentListApi.middleware,
      azureDashboardAPI.middleware,
      customLineItemsAPI.middleware,
      creditsAPI.middleware,
      federatedAccessApi.middleware,
      marketplaceChargesAPI.middleware,
      supportChargesAPI.middleware,
      managementReportsAPI.middleware,
      managementOpportunitiesAPI.middleware,
      mapHistoricalData.middleware,
      logoAPI.middleware,
      assessmentInsightsAPI.middleware,
      azureOptimizationOverviewApi.middleware,
      healthEventsAPI.middleware,
      foundationalBestPracticeApi.middleware,
      portalTenantListApi.middleware,
      awsCostOptimizationOverviewApi.middleware
    ),
});

export default store;
