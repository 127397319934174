import { WHITELABEL_PARTNER_LIST, MC, DC } from "../utils/app-constants.json";
import styles from "../modules/authentication/components/login/Login.module.scss";
import {
  CONTACT_URL,
  DINOCLOUD_URL,
} from "../modules/authentication/constants";

export function getPartnerType() {
  const partnerType = localStorage.getItem("whitelabellingPartnerType");

  if (partnerType && WHITELABEL_PARTNER_LIST.includes(partnerType)) {
    return partnerType;
  } else {
    return MC;
  }
}

export function getLoginLink() {
  const partnerType = localStorage.getItem("whitelabellingPartnerType");

  if (partnerType && WHITELABEL_PARTNER_LIST.includes(partnerType)) {
    return "/login?partner=" + partnerType;
  } else {
    return "/login";
  }
}

export const getPartnerLogo = (partnerType) => {
  switch (partnerType) {
    case MC:
      return "/images/DAY2Logo.jpg";
    case DC:
      return "/images/DinoCloud.svg";
    default:
      return "/images/DAY2Logo.jpg";
  }
};

export const getPartnerAltText = (partnerType) => {
  switch (partnerType) {
    case MC:
      return MC;
    case DC:
      return DC;
    default:
      return MC;
  }
};

export const getPartnerBackgroundImageClassName = (partnerType) => {
  switch (partnerType) {
    case MC:
      return "montycloud-bg";
    case DC:
      return "dino-cloud-bg";
    default:
      return "montycloud-bg";
  }
};

export const getPartnerButtonClassName = (partnerType) => {
  switch (partnerType) {
    case MC:
      return "btn-primary";
    case DC:
      return styles.dinoCloudPartnerBtnCol;
    default:
      return "btn-primary";
  }
};

export const getPartnerTextClassName = (partnerType) => {
  switch (partnerType) {
    case MC:
      return "orange-text";
    case DC:
      return styles.dinoCloudPartnerLoginText;
    default:
      return "orange-text";
  }
};

export const getPartnerContactUsLink = (partnerType) => {
  switch (partnerType) {
    case MC:
      return CONTACT_URL;
    case DC:
      return DINOCLOUD_URL;
    default:
      return CONTACT_URL;
  }
};
