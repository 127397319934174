import { toast } from "react-toastify";

import { NOTIFICATIONS_UPDATED } from "../reducers/notifications";

export const postNotification =
  (message, type = "info") =>
  (dispatch, getState) => {
    const { notification } = getState();
    notification.notifications.unshift({ message, time: new Date() });
    dispatch({
      type: NOTIFICATIONS_UPDATED,
      payload: notification.notifications,
    });
    if (type === "danger") {
      toast.error(message, {
        position: "bottom-right",
      });
    } else {
      toast[type](message, {
        position: "bottom-right",
      });
    }
  };

export const postCustomNotification = (
  callback,
  { width = "360px", ...props } = {}
) => {
  toast((toastProps) => callback(toastProps), {
    position: "bottom-right",
    icon: false,
    closeButton: false,
    hideProgressBar: true,
    bodyClassName: "p-0",
    className: `p-0 mc-custom-toast`,
    style: { width },
    ...props,
  });
};
