import React, { useEffect } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import styles from "./Account.module.scss";
import { ACCOUNT_TYPES } from "../../utils/account-constants";
import { useSelector, useDispatch } from "react-redux";
import { setAccountType } from "../../../first-run/actions/firstRun";
import classNames from "classnames";
import MCButton from "../../../../components/MCButton/MCButton";
import { useTranslation } from "react-i18next";
import { setDisableNextStep } from "../../actions/account";
import { useInitialMount } from "../../../../hooks/useInitialMount";

const AccountTypeSelector = ({
  isFre,
  previousStep,
  nextStep,
  disablePreviousStep = false,
}) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const isMounted = useInitialMount();

  const { accountType } = useSelector(({ firstRun }) => {
    return {
      accountType: firstRun.selectedAccountType,
    };
  });

  const onAccountTypeSelected = (selectedAccountType) => {
    dispatch(setAccountType(selectedAccountType));
  };

  useEffect(() => {
    if (!isFre && isMounted) {
      dispatch(setDisableNextStep(false));
    }
  }, [dispatch, isFre, isMounted]);

  return (
    <>
      {isFre && (
        <div className={styles.accountTypeHeader}>{t("AccountType.Title")}</div>
      )}
      <div
        className={classNames(
          styles.accountTypeCardContainer,
          !isFre && styles.accountTypeHalfWidth
        )}
      >
        <Checkbox
          id="standalone"
          type="radio"
          renderVerticallyAligned
          checked={accountType === ACCOUNT_TYPES.STANDALONE}
          name="accountType"
          content={
            <AccountTypeSelectorContent
              name={t("AccountType.Standalone")}
              isChecked={accountType === ACCOUNT_TYPES.STANDALONE}
            />
          }
          onChange={(e) => onAccountTypeSelected(ACCOUNT_TYPES.STANDALONE)}
        />
        <Checkbox
          id="management"
          type="radio"
          renderVerticallyAligned
          checked={accountType === ACCOUNT_TYPES.MANAGEMENT}
          name="accountType"
          content={
            <AccountTypeSelectorContent
              name={t("AccountType.Management")}
              isChecked={accountType === ACCOUNT_TYPES.MANAGEMENT}
            />
          }
          onChange={(e) => onAccountTypeSelected(ACCOUNT_TYPES.MANAGEMENT)}
        />
      </div>
      {isFre && (
        <div className={styles.accountTypeCardFooter}>
          <MCButton
            className="bs4-mt-4 bs4-mb-4 float-left"
            text={
              <>
                <i className="fa fa-caret-left bs4-mr-2" />
                {t("btn.previous")}
              </>
            }
            onClick={() => {
              onAccountTypeSelected(ACCOUNT_TYPES.STANDALONE);
              previousStep();
            }}
            size="lg"
            disabled={disablePreviousStep}
          />
          <MCButton
            size="lg"
            text={
              <>
                {t("btn.next")}
                <i className="fa fa-caret-right bs4-ml-2" />
              </>
            }
            color="mc-blue"
            onClick={nextStep}
            className={classNames(styles.nextButton, "bs4-mt-4 bs4-mb-4 float-left")}
          />
        </div>
      )}
    </>
  );
};

const AccountTypeSelectorContent = ({ name, isChecked }) => {
  return (
    <div
      className={classNames(
        "d-flex flex-column",
        isChecked ? "mc-text-base-bold" : "mc-text-base-regular"
      )}
    >
      <div className={styles.accountTypeSelector}>{name}</div>
    </div>
  );
};

export default AccountTypeSelector;
