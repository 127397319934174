/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import headerStyles from "../../../components/Header/Header.module.scss";
import LoggingOutLoader from "../../../components/Header/LoggingOutLoader";
import s from "../../../components/Layout/Layout.module.scss";
import SupportTicketForFREAccount from "../../../components/SupportTicket/components/SupportTicketForFREAccount";
import { isArrayWithLength } from "../../../utils/array-methods";
import { getFREStepIndex } from "../../../utils/handle-redirection";
import { logoutUser } from "../../authentication/actions/auth";
import { getRegions } from "../../region/actions/regions";
import { setFeaturesList } from "../actions/new-first-run";
import { isSafari } from "../constants";
import ConfigureCloudProvider from "./ConfigureCloudProvider";
import ConfigureOptionalFeatures from "./ConfigureOptionalFeatures";
import Day2FeatureSelection from "./Day2FeatureSelection";
import FirstRunOverview from "./FirstRunOverview";
import s1 from "./NewFirstRun.module.scss";
import WelcomeToDay2 from "./WelcomeToDay2";
import { withRouter } from "@hocs/withRouter";
import { CloudOpsCopilotFeatureStep } from "./CloudOpsCopilotFeatureStep";

const NewFirstRun = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun", "common"]);

  const {
    dashboardTheme,
    subscriptionDetails,
    customerPreferences,
    featureList,
    submittedFeedback,
    accountList,
    successfullySubscribedToPlan,
  } = useSelector(({ layout, auth, newFirstRun, accounts, supportTicket }) => {
    return {
      dashboardTheme: layout.dashboardTheme,
      subscriptionDetails: auth.subscriptionDetails,
      customerPreferences: newFirstRun.customerPreferences,
      featureList: newFirstRun.featureList,
      submittedFeedback: supportTicket.isCreated,
      accountList: accounts.accountList,
      successfullySubscribedToPlan: newFirstRun.successfullySubscribedToPlan,
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(getRegions());
  }, []);

  useEffect(() => {
    if (isArrayWithLength(customerPreferences)) {
      const _featureList = [...featureList];
      _featureList.forEach((ele) => {
        if (customerPreferences.includes(ele.id)) {
          ele.selected = true;
          ele.options &&
            ele.options.forEach((option) => {
              if (customerPreferences.includes(option.id)) {
                option.selected = true;
              }
            });
        }
      });
      dispatch(setFeaturesList(_featureList));
    }
  }, [customerPreferences]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showLogoutLoader, setShowLogoutLoader] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showConnectAccountHelp, setConnectAccountHelp] = useState(false);
  const [timeout] = useState(1000 * 20);
  const [isTimedOut, setIsTimeout] = useState(false);
  const [chatWindowOpenedOnce, setChatWindowOpened] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const username = localStorage.getItem("username");

  useEffect(() => {
    if (firstRender) {
      let parentStepIndex = getFREStepIndex(
        subscriptionDetails,
        customerPreferences,
        successfullySubscribedToPlan
      );
      parentStepIndex && setActiveIndex(parentStepIndex);
      setTimeout(() => {
        setFirstRender(false);
      }, 2000);
    }
  }, [subscriptionDetails, customerPreferences]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === steps.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? steps.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const doLogout = () => {
    setShowLogoutLoader(true);
    dispatch(logoutUser());
  };

  const toggleConnectAccountHelp = () => {
    setConnectAccountHelp(!showConnectAccountHelp);
  };

  const handleOnIdle = (e) => {
    if (!isTimedOut && activeIndex !== 2) {
      setIsTimeout(true);
      openChatWindow();
    }
  };

  const handleChatWindow = () => {
    openChatWindow();
    setChatWindowOpened(true);
  };

  const openChatWindow = () => {
    window.fcWidget && window.fcWidget.open();
  };

  const toggleProfileDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const steps = [
    {
      stepNumber: "1",
      id: "WelcomeToDay2",
      component: (
        <div className={s1.welcomeDivPadding}>
          <WelcomeToDay2 next={next} />
        </div>
      ),
    },
    {
      stepNumber: "2",
      id: "SelectFeatures",
      component: (
        <div className={s1.welcomeDivPadding}>
          <Day2FeatureSelection parentNext={next} history={history} />,
        </div>
      ),
    },
    {
      stepNumber: "3",
      id: "CopilotFeatureAnnoucement",
      heading: t("step.copilotFeature"),
      component: <CloudOpsCopilotFeatureStep parentNext={next} />,
    },
    {
      stepNumber: "4",
      id: "ConfigureDay2Context",
      heading: t("step.connectAccount"),
      component: <ConfigureCloudProvider parentNext={next} />,
    },
    {
      stepNumber: "5",
      id: "ConfigureOptionalFeatures",
      heading: t("step.configureAccess"),
      component: <ConfigureOptionalFeatures parentNext={next} />,
    },
    {
      stepNumber: "6",
      id: "Overview",
      component: <FirstRunOverview parentNext={next} history={history} />,
    },
  ];

  const isFirstTwoSteps = activeIndex === 0 || activeIndex === 1;

  const slides = steps.map((step, index) => {
    const hideHeadingSteps = step.stepNumber === "1" || step.stepNumber === "2";
    const lastStep = steps.length.toString() === step.stepNumber;
    const stepIndex = step.stepNumber;
    return (
      <CarouselItem
        onExiting={() => setAnimating(false)}
        onExited={() => setAnimating(false)}
        key={step.id}
        className={classnames(s1.carouselItem)}
      >
        <div
          className={classnames(
            isFirstTwoSteps ? s1.welcomeDiv : s1.welcomeDivLarge
          )}
        >
          {!(hideHeadingSteps || lastStep) && (
            <div className={classnames("bs4-mb-2")}>
              <span className={classnames(s1.StepNumberContainer, s1.Active)}>
                <span className={s1.StepNumber}>
                  {parseInt(step.stepNumber) - 1}
                </span>
              </span>
              {step.heading && (
                <span className={classnames(s1.StepHeading)}>
                  {step.heading}
                </span>
              )}
            </div>
          )}
          {activeIndex === stepIndex - 1 && step.component}
        </div>
      </CarouselItem>
    );
  });

  const handleOnLogout = () => {
    if (activeIndex < 2 && !chatWindowOpenedOnce) {
      handleChatWindow();
    } else if (
      activeIndex === 2 &&
      isArrayWithLength(accountList) &&
      !submittedFeedback
    ) {
      toggleConnectAccountHelp();
    } else {
      doLogout();
    }
  };

  return (
    <div className={s1.BackGround}>
      {showConnectAccountHelp && (
        <SupportTicketForFREAccount toggleModal={toggleConnectAccountHelp} />
      )}

      <div
        className={[
          s1.FirstRun,
          s.sidebarClose,
          "sing-dashboard",
          `dashboard-${dashboardTheme}`,
          isFirstTwoSteps ? s1.FirstStep : "",
        ].join(" ")}
      >
        <div className={classnames(s1.firstRunContainer)}>
          <Dropdown
            isOpen={isUserDropdownOpen}
            toggle={toggleProfileDropdown}
            className={classnames(
              s1.logout,
              activeIndex > 1 && s1.blackColor,
              headerStyles.DropdownRight
            )}
          >
            <DropdownToggle nav className="bs4-text-white">
              <span
                className={classnames(
                  headerStyles.UsernameCircle,
                  "fw-semi-bold"
                )}
              >
                {username[0]}
              </span>
            </DropdownToggle>
            <DropdownMenu right className={classnames("super-colors")}>
              <DropdownItem disabled={true}>{username}</DropdownItem>
              <DropdownItem onClick={() => handleOnLogout()}>
                <i className="la la-sign-out" /> {t("feedback:logOut")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {showLogoutLoader && (
            <div>
              <LoggingOutLoader />
            </div>
          )}
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
            keyboard={false}
            slide={!isSafari}
            className={classnames(s1.carousel)}
          >
            {slides}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewFirstRun);
