import config from "../config";
import i18n from "../config/locale/i18n";
import {
  CUSTOMER_ROLE_TYPE,
  SWITCH_ORGNIZATION,
  CLOUDPROVIDERS,
} from "../utils/app-constants.json";
import {
  CO_PILOT_BASE_PATH,
  HOME_BASE_PATH,
  REPORT_SCHEDULES_PATH,
} from "../utils/app-paths.json";
import { isArrayWithLength } from "./array-methods";
import {
  isAppAccessUserFn,
  isDay2AdminUserFn,
  isDeptAdminUserFn,
  isFreeModeUser,
  isOnlyAdminUserFn,
  isReportsAccessUserFn,
} from "./users";
import { SIDEBAR_DATA_TEST_IDS } from "../components/Sidebar/constants";

export const getInitialPage = (accessType) => {
  let initialPageUrl = "/app/home/dashboard";
  const freeUser = isFreeModeUser();
  switch (accessType) {
    case "report-access":
      initialPageUrl = "/app/reports";
      break;
    case "day2-admin-access":
    case "full-access": {
      if (freeUser) {
        initialPageUrl = "/app/home/dashboard";
      }
      break;
    }
    case "app-access":
      initialPageUrl = "/app/projects";
      break;
    case "unassigned":
      initialPageUrl = "/app/projects";
      break;
    case "infrastructure-access":
    case "operation-access":
    default:
      break;
  }
  return initialPageUrl;
};

const appAccessRestrictedPaths = [HOME_BASE_PATH];
const noRoleAccessRestrictedPaths = [HOME_BASE_PATH, CO_PILOT_BASE_PATH];
const reportAccessRestrictedPaths = [REPORT_SCHEDULES_PATH];

export const menuKeys = {
  HOME: "home",
  GENERAL: "general",
  PROJECTS: "projects",
  ORGANIZATIONS: "organizations",
  GENERAL_ORGANIZATIONS: "general_organization",
  INVENTORY: "inventory",
  SERVERS: "servers",
  ALL_RESOURCES: "all-resources",
  SERVICES: "services",
  DAY2_OPS: "day2-ops",
  GOVERNANCE: "governance",
  TAG_POLICIES: "tag-policies",
  ALL_POLICIES: "all-policies",
  CLOUDOPS_DASHBBOARD: "cloudops-dashboard",
  AUTOMATION: "automation",
  COST: "cost",
  OPTIMIZATION: "optimization",
  SECURITY: "security",
  COMPLIANCE: "compliance",
  REPORTS: "reports",
  SETTINGS: "settings",
  ACCOUNTS: "accounts",
  USERS: "users",
  SSO: "sso",
  DAY2_BOTS: "day2bots",
  CONNECTORS: "connectors",
  ASSESSMENTS: "assessments",
  WELL_ARCHITECTED_REVIEW: "well-architected-review",
  FOUNDATIONAL_BEST_PRACTICES: "foundational-best-practices",
  TENANTS: "organizations",
  BILLING_SETUP: "billing-setup",
  MANAGEMENT: "management",
  MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES:
    "management-foundational-best-practices",
  FEDERATED_ACCESS: "federated-access",
  OPPORTUNITIES_REPORT: "opportunities-report",
};

const generalMenuItem = {
  key: menuKeys.GENERAL,
  name: i18n.t("settings:general"),
  title: i18n.t("settings:general"),
  dataTourKey: "navlink-projects",
  firstTab: ["general"],
  link: "settings/general",
  icon: "gear",
  activeIcon: "gear",
  dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_GENERAL,
};

const settingsSideMenuItems = [
  generalMenuItem,
  {
    key: menuKeys.ACCOUNTS,
    name: i18n.t("settings:accounts"),
    dataTourKey: "navlink-accounts",
    firstTab: ["accounts"],
    link: "settings/accounts",
    icon: "gear-user",
    activeIcon: "gear-user",
    iconProps: {
      fill: "currentColor",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_ACCOUNTS,
  },
  {
    key: menuKeys.USERS,
    name: i18n.t("settings:users"),
    dataTourKey: "navlink-users",
    firstTab: ["users"],
    link: "settings/users",
    icon: "user-large",
    activeIcon: "user-large",
    iconProps: {
      stroke: "currentColor",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_USERS,
  },
  {
    key: menuKeys.FEDERATED_ACCESS,
    name: i18n.t("settings:federatedAccess"),
    dataTourKey: "navlink-federated-access",
    firstTab: ["federated-access"],
    link: "settings/federated-access",
    icon: "display",
    activeIcon: "display",
    iconProps: {
      stroke: "currentColor",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_FEDERATED_ACCESS,
  },
  {
    key: menuKeys.SSO,
    name: i18n.t("settings:sso"),
    dataTourKey: "navlink-sso",
    firstTab: ["sso"],
    link: "settings/sso",
    icon: "shield-keyhole",
    activeIcon: "shield-keyhole",
    iconProps: {
      stroke: "currentColor",
      fill: "none",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_SSO,
  },
  {
    key: menuKeys.DAY2_BOTS,
    name: i18n.t("settings:day2Bots"),
    dataTourKey: "navlink-day2bots",
    firstTab: ["bots"],
    link: "settings/bots",
    icon: "robot",
    activeIcon: "robot",
    iconProps: {
      stroke: "currentColor",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_DAY2BOTS,
  },
  {
    key: menuKeys.CONNECTORS,
    name: i18n.t("settings:connectors"),
    dataTourKey: "navlink-connectors",
    firstTab: ["connectors"],
    link: "settings/connectors",
    icon: "plug",
    activeIcon: "plug",
    iconProps: {
      stroke: "currentColor",
    },
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_SETTINGS_CONNECTORS,
  },
];

const awsSideMenuItems = [
  {
    key: menuKeys.HOME,
    name: "Home",
    dataTourKey: "navlink-home",
    firstTab: ["home"],
    link: "home",
    icon: "mc-home",
    activeIcon: "mc-home",
    isProFeature: false,
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_HOME,
  },
  {
    key: menuKeys.PROJECTS,
    name: "Projects",
    dataTourKey: "navlink-projects",
    firstTab: ["projects", "project", "applications"],
    link: "projects",
    icon: "mc-projects",
    activeIcon: "mc-projects",
    isProFeature: false,
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_PROJECTS,
  },
  {
    name: "Assessments",
    key: menuKeys.ASSESSMENTS,
    children: [
      {
        key: menuKeys.WELL_ARCHITECTED_REVIEW,
        name: "Well-Architected",
        dataTourKey: "navlink-assessments",
        firstTab: ["assessments"],
        link: "assessments",
        isProFeature: true,
        icon: "mc-wafr",
        activeIcon: "mc-wafr",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_WELL_ARCHITECTED,
      },
      {
        key: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        name: "Foundational Best Practices",
        dataTourKey: `navlink-${menuKeys.FOUNDATIONAL_BEST_PRACTICES}`,
        firstTab: [menuKeys.FOUNDATIONAL_BEST_PRACTICES],
        link: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        isProFeature: true,
        icon: "mc-fpb",
        activeIcon: "mc-fpb",
        featureKey: "fbpEnabled",
        dataTestId:
          SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_FOUNDATIONAL_BEST_PRACTICES,
      },
    ],
  },
  {
    name: "Inventory",
    key: menuKeys.INVENTORY,
    children: [
      {
        key: menuKeys.SERVERS,
        name: "Servers",
        dataTourKey: "navlink-servers",
        firstTab: ["servers"],
        link: "servers",
        icon: "mc-servers",
        activeIcon: "mc-servers",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_SERVERS,
      },
      {
        key: menuKeys.ALL_RESOURCES,
        name: "All Resources",
        dataTourKey: "navlink-all-resources",
        firstTab: ["all-resources"],
        link: "all-resources/inventory",
        icon: "mc-resources",
        activeIcon: "mc-resources",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_ALL_RESOURCES,
      },
    ],
  },
  {
    name: "Governance",
    key: menuKeys.GOVERNANCE,
    children: [
      {
        key: menuKeys.TAG_POLICIES,
        name: "Tags",
        firstTab: ["governance", "governance/tags"],
        link: "governance/tags",
        icon: "mc-tags",
        activeIcon: "mc-tags",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_TAGS,
      },
      {
        key: menuKeys.ALL_POLICIES,
        name: "Policies",
        firstTab: ["policies", "policies/patch-compliance"],
        link: "policies/patch-compliance",
        icon: "mc-policies",
        activeIcon: "mc-policies",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_POLICIES,
      },
      {
        key: menuKeys.SERVICES,
        name: "Service Catalog",
        dataTourKey: "navlink-services",
        firstTab: ["services"],
        link: "services",
        icon: "mc-service",
        activeIcon: "mc-service",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_SERVICE_CATALOG,
      },
    ],
  },
  {
    name: "DAY2 CloudOps",
    key: menuKeys.DAY2_OPS,
    children: [
      {
        key: menuKeys.COST,
        name: "Cost",
        dataTourKey: "navlink-cost",
        firstTab: ["cost", "billing-groups", "pricing-plans"],
        link: "cost",
        icon: "mc-cost",
        activeIcon: "mc-cost",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_COST,
      },
      {
        key: menuKeys.OPTIMIZATION,
        name: "Optimization",
        dataTourKey: "navlink-optimization",
        firstTab: ["optimization"],
        link: "optimization",
        icon: "mc-optimization",
        activeIcon: "mc-optimization",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_OPTIMIZATION,
        subChildren: [
          {
            key: "/optimization/summary",
            name: "Cost Optimization Overview",
            link: "optimization/summary",
            dataTourKey: "navlink-optimization",
            firstTab: ["/optimization/summary"],
            dataTestId: "sidebar-aws-optimization-overview",
          },
          {
            key: "optimization/savings-opportunities",
            name: "Savings Opportunities",
            dataTourKey: "navlink-savingsOpportunities",
            firstTab: ["optimization/savings-opportunities"],
            link: "optimization/savings-opportunities",
            dataTestId: "sidebar-aws-optimization-savings-opportunities",
          },
          {
            key: "optimization/reservation-opportunities",
            name: "Reservation Opportunities",
            dataTourKey: "navlink-reservationOpportunities",
            firstTab: ["optimization/reservation-opportunities"],
            link: "optimization/reservation-opportunities",
            dataTestId: "sidebar-aws-optimization-reservation-opportunities",
          },
        ],
      },
      {
        key: menuKeys.SECURITY,
        name: "Security",
        dataTourKey: "navlink-security",
        firstTab: ["security"],
        link: "security",
        icon: "mc-security",
        activeIcon: "mc-security",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_SECURITY,
      },
      {
        key: menuKeys.COMPLIANCE,
        name: "Compliance",
        dataTourKey: "navlink-compliance",
        firstTab: ["compliance"],
        link: "compliance",
        icon: "shield-check",
        activeIcon: "shield-check",
        iconProps: {
          stroke: "currentColor",
          fill: "none",
        },
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_COMPLIANCE,
      },
      {
        key: menuKeys.AUTOMATION,
        name: "Automation",
        dataTourKey: "navlink-automation",
        firstTab: ["automation"],
        link: "automation/tasks/catalog",
        isProFeature: true,
        icon: "code-compare",
        activeIcon: "code-compare",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_AUTOMATION,
        iconProps: {
          stroke: "currentColor",
        },
      },
    ],
  },
  {
    key: menuKeys.REPORTS,
    name: "Reports",
    dataTourKey: "navlink-reports",
    firstTab: ["reports"],
    link: "reports",
    isProFeature: true,
    icon: "mc-reports",
    activeIcon: "mc-reports",
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AWS_REPORTS,
  },
];

const azureSideMenuItems = [
  {
    key: menuKeys.HOME,
    name: "Home",
    dataTourKey: "navlink-home",
    firstTab: ["home"],
    link: "home",
    icon: "mc-home",
    activeIcon: "mc-home",
    isProFeature: false,
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_HOME,
  },
  {
    name: "Assessments",
    key: menuKeys.ASSESSMENTS,
    isUpcomingFeature: true,
    children: [
      {
        key: menuKeys.WELL_ARCHITECTED_REVIEW,
        name: "Well-Architected",
        dataTourKey: "navlink-assessments",
        firstTab: ["assessments"],
        link: "assessments",
        isProFeature: true,
        icon: "mc-wafr",
        activeIcon: "mc-wafr",
        isUpcomingFeature: true,
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_WELL_ARCHITECTED,
      },
      {
        key: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        name: "Foundational Best Practices",
        dataTourKey: "navlink-" + menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        firstTab: [menuKeys.FOUNDATIONAL_BEST_PRACTICES],
        link: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        isProFeature: true,
        icon: "mc-fpb",
        activeIcon: "mc-fpb",
        isUpcomingFeature: true,
        dataTestId:
          SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_FOUNDATIONAL_BEST_PRACTICES,
      },
    ],
  },
  {
    name: "Inventory",
    key: menuKeys.INVENTORY,
    children: [
      {
        key: menuKeys.ALL_RESOURCES,
        name: "All Resources",
        dataTourKey: "navlink-all-resources",
        firstTab: ["all-resources"],
        link: "all-resources/inventory",
        icon: "mc-resources",
        activeIcon: "mc-resources",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_ALL_RESOURCES,
      },
    ],
  },
  {
    name: "DAY2 CloudOps",
    key: menuKeys.DAY2_OPS,
    children: [
      {
        key: menuKeys.COST,
        name: "Cost",
        dataTourKey: "navlink-cost",
        firstTab: ["cost", "billing-groups", "pricing-plans"],
        link: "cost",
        icon: "mc-cost",
        activeIcon: "mc-cost",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_COST,
      },
      {
        key: menuKeys.OPTIMIZATION,
        name: "Optimization",
        dataTourKey: "navlink-optimization",
        firstTab: ["optimization"],
        link: "optimization",
        icon: "mc-optimization",
        activeIcon: "mc-optimization",
        isNewFeature: true,
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_OPTIMIZATION,
        subChildren: [
          {
            key: "/optimization/summary",
            name: "Cost Optimization Overview",
            link: "optimization/summary",
            dataTourKey: "navlink-optimization",
            firstTab: ["/optimization/summary"],
            dataTestId:
              SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_OPTIMIZATION_OVERVIEW,
          },
          {
            key: "optimization/savings-opportunities",
            name: "Savings Opportunities",
            dataTourKey: "navlink-savingsOpportunities",
            firstTab: ["optimization/savings-opportunities"],
            link: "optimization/savings-opportunities",
            dataTestId:
              SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_OPTIMIZATION_SAVINGS_OPPORTUNITIES,
          },
          {
            key: "optimization/reservation-opportunities",
            name: "Reservation Opportunities",
            dataTourKey: "navlink-reservationOpportunities",
            firstTab: ["optimization/reservation-opportunities"],
            link: "optimization/reservation-opportunities",
            dataTestId:
              SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_OPTIMIZATION_RESERVATION_OPPORTUNITIES,
          },
        ],
      },
      {
        key: menuKeys.SECURITY,
        name: "Security",
        dataTourKey: "navlink-security",
        firstTab: ["security"],
        link: "security",
        icon: "mc-security",
        activeIcon: "mc-security",
        isUpcomingFeature: true,
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_SECURITY,
      },

      {
        key: menuKeys.COMPLIANCE,
        name: "Compliance",
        dataTourKey: "navlink-compliance",
        firstTab: ["compliance"],
        link: "compliance",
        isUpcomingFeature: true,
        icon: "shield-check",
        activeIcon: "shield-check",
        iconProps: {
          stroke: "currentColor",
          fill: "none",
        },
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_COMPLIANCE,
      },
      {
        key: menuKeys.AUTOMATION,
        name: "Automation",
        dataTourKey: "navlink-automation",
        firstTab: ["automation"],
        link: "automation/tasks/catalog",
        isProFeature: true,
        isUpcomingFeature: true,
        icon: "code-compare",
        activeIcon: "code-compare",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_AUTOMATION,
        iconProps: {
          stroke: "currentColor",
        },
      },
    ],
  },
  {
    key: menuKeys.REPORTS,
    name: "Reports",
    dataTourKey: "navlink-reports",
    firstTab: ["reports"],
    link: "reports",
    isProFeature: true,
    icon: "mc-reports",
    activeIcon: "mc-reports",
    dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_AZURE_REPORTS,
  },
];

const managementSideMenuItems = [
  {
    name: "Management",
    key: menuKeys.MANAGEMENT,
    children: [
      {
        key: menuKeys.BILLING_SETUP,
        name: "Billing",
        dataTourKey: "navlink-billing-setup",
        firstTab: ["billing-setup"],
        link: "billing-setup",
        icon: "mc-cost",
        activeIcon: "mc-cost",
        isProFeature: false,
        featureKey: "MTEnabled",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_MANAGEMENT_BILLING,
      },
      {
        key: menuKeys.OPPORTUNITIES_REPORT,
        name: "Opportunities",
        dataTourKey: "navlink-management-opportunities",
        firstTab: ["management-opportunities"],
        link: "management-opportunities",
        icon: "lightbulb-on",
        activeIcon: "lightbulb-on",
        isProFeature: false,
        featureKey: "MTEnabled",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_MANAGEMENT_OPPORTUNITIES,
        iconProps: {
          stroke: "currentColor",
          fill: "none",
        },
      },
      {
        key: menuKeys.BILLING_SETUP,
        name: "Reports",
        dataTourKey: "navlink-management-reports",
        firstTab: ["management-reports"],
        link: "management-reports",
        icon: "mc-reports",
        activeIcon: "mc-reports",
        isProFeature: false,
        featureKey: "MTEnabled",
        dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_MANAGEMENT_REPORTS,
      },
    ],
  },
];

const organisationMenuItem = {
  key: menuKeys.TENANTS,
  name: "Tenants",
  dataTourKey: "navlink-organizations",
  firstTab: ["organizations"],
  link: "organizations",
  isProFeature: false,
  icon: "tenant",
  activeIcon: "tenant",
  className: "org-icon-fix",
  featureKey: "MTEnabled",
  dataTestId: SIDEBAR_DATA_TEST_IDS.SIDEBAR_MANAGEMENT_TENANTS,
};

const managementFBPItem = {
  key: menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  name: "Foundational Best Practices",
  dataTourKey: "navlink-" + menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  firstTab: [menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES],
  link: menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  isProFeature: true,
  icon: "mc-fpb",
  activeIcon: "mc-fpb",
};

//allowed menus
const day2AdminMenus = [
  menuKeys.MANAGEMENT,
  menuKeys.BILLING_SETUP,
  menuKeys.TENANTS,
  menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
];

const adminMenus = [
  menuKeys.HOME,
  menuKeys.PROJECTS,
  menuKeys.ASSESSMENTS,
  menuKeys.INVENTORY,
  menuKeys.GOVERNANCE,
  menuKeys.TAG_POLICIES,
  menuKeys.DAY2_OPS,
  menuKeys.REPORTS,
  menuKeys.FEDERATED_ACCESS,
];

const projectOwnerMenus = [
  menuKeys.HOME,
  menuKeys.PROJECTS,
  menuKeys.INVENTORY,
  menuKeys.SERVICES,
  menuKeys.SECURITY,
  menuKeys.COMPLIANCE,
  menuKeys.AUTOMATION,
  menuKeys.REPORTS,
];

const noRoleMenus = [menuKeys.PROJECTS, menuKeys.SERVICES];

const reportsAccessMenus = [menuKeys.REPORTS];

const defaultMenus = [menuKeys.HOME, menuKeys.PROJECTS];

const allowedPagesForAll = ["notifications"];

export const getSettingsSideMenuItems = ({ store, featureFlaggedList }) => {
  let menuItems = [...settingsSideMenuItems];
  if (!featureFlaggedList.federatedAccessEnabled) {
    menuItems = menuItems.filter((menuItem) => {
      return menuItem.key !== menuKeys.FEDERATED_ACCESS;
    });
  }

  return menuItems;
};

const getItemsBasedOnRole = (allowedKeys, cloudProviderType) => {
  let menuItems = [];
  let completeSideMenutItems =
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? [...awsSideMenuItems]
      : [...azureSideMenuItems];
  if (isDay2AdminUserFn()) {
    completeSideMenutItems = [
      ...managementSideMenuItems,
      organisationMenuItem,
      ...(config.demoApp ? [managementFBPItem] : []),
      ...completeSideMenutItems,
    ];
  }

  if (isArrayWithLength(completeSideMenutItems)) {
    completeSideMenutItems.forEach((menuItem) => {
      if (allowedKeys.includes(menuItem.key)) {
        menuItems.push(menuItem);
      } else {
        const childMenu =
          isArrayWithLength(menuItem.children) &&
          menuItem.children.filter((child) => allowedKeys.includes(child.key));

        if (childMenu && isArrayWithLength(childMenu)) {
          let newMenuItem = { ...menuItem };
          newMenuItem.children = childMenu;
          menuItems.push(newMenuItem);
        }
      }
    });
  }
  return menuItems;
};

const removeFeatureFlaggedItems = ({ filteredItems, featureFlaggedList }) => {
  const { fbpEnabled } = featureFlaggedList;
  //enhance this to a switch logic when there are more feature flagged items
  return filteredItems.map((menuNav) => {
    if (isArrayWithLength(menuNav.children)) {
      return {
        ...menuNav,
        children: menuNav.children.filter((child) => {
          const fbpEnableCondition =
            !fbpEnabled && child.key === menuKeys.FOUNDATIONAL_BEST_PRACTICES;
          if (fbpEnableCondition) {
            return false;
          }
          return true;
        }),
      };
    }
    return menuNav;
  });
};

export const getSideMenuItems = ({
  accessType,
  featureFlaggedList,
  cloudProviderType,
}) => {
  const menuItems = (
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? awsSideMenuItems
      : azureSideMenuItems
  ).map((item) => ({ ...item }));

  let filteredItems = [...menuItems];
  switch (accessType) {
    case "full-access":
    case "day2-admin-access":
      filteredItems = getItemsBasedOnRole(adminMenus, cloudProviderType);
      break;
    case "department-access":
      filteredItems = getItemsBasedOnRole(projectOwnerMenus, cloudProviderType);
      break;
    case "app-access":
    case "unassigned":
      filteredItems = getItemsBasedOnRole(noRoleMenus, cloudProviderType);
      break;
    case "report-access":
      filteredItems = getItemsBasedOnRole(
        reportsAccessMenus,
        cloudProviderType
      );
      break;
    default:
      filteredItems = getItemsBasedOnRole(defaultMenus, cloudProviderType);
      break;
  }

  filteredItems = removeFeatureFlaggedItems({
    filteredItems,
    featureFlaggedList,
  });

  return filteredItems;
};

export const getManagementSideMenuItems = ({ featureFlaggedList }) => {
  let updatedManagementSideMenuItems = [...managementSideMenuItems];
  const { MTEnabled } = featureFlaggedList;

  if (isDay2AdminUserFn() && MTEnabled) {
    updatedManagementSideMenuItems = managementSideMenuItems.map((item) => {
      if (item.key === menuKeys.MANAGEMENT) {
        const children = [{ ...organisationMenuItem }, ...item.children];
        if (config.demoApp) {
          // adding FBP menu item before the reports (last menu item)
          const managementFBPMenuPosition = children?.length - 1;
          children?.splice(managementFBPMenuPosition, 0, {
            ...managementFBPItem,
          });
        }
        return {
          ...item,
          children,
        };
      }
      return item;
    });
  }
  return updatedManagementSideMenuItems;
};

const isAllowedRoute = (menuitems, location, featureFlag) => {
  let hasLocation = false;
  hasLocation = allowedPagesForAll.includes(location);
  if (!hasLocation) {
    menuitems.forEach((menuItem) => {
      if (menuItem.featureKey && !featureFlag[menuItem.featureKey]) {
        return;
      }
      if (menuItem.firstTab && menuItem.firstTab.includes(location)) {
        hasLocation = true;
      } else if (isArrayWithLength(menuItem.children)) {
        menuItem.children.forEach((childMenu) => {
          if (
            childMenu.featureKey &&
            !featureFlag.isInitialFeatureFetchInProgress &&
            !featureFlag[childMenu.featureKey]
          ) {
            return;
          }
          if (childMenu.firstTab.includes(location)) {
            hasLocation = true;
          }
        });
      }
    });
  }

  return hasLocation;
};

export const isAllowedRouteForUser = (
  location,
  cloudProviderType,
  featureFlag
) => {
  const url = location.pathname.split("/")[2];

  let items = (
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? awsSideMenuItems
      : azureSideMenuItems
  ).map((item) => ({ ...item }));

  if (isDay2AdminUserFn()) {
    items = getItemsBasedOnRole(
      [...day2AdminMenus, ...adminMenus],
      cloudProviderType
    );
  } else if (isOnlyAdminUserFn()) {
    items = getItemsBasedOnRole(adminMenus, cloudProviderType);
  } else if (isDeptAdminUserFn()) {
    items = getItemsBasedOnRole(projectOwnerMenus, cloudProviderType);
  } else if (isAppAccessUserFn()) {
    items = getItemsBasedOnRole(noRoleMenus, cloudProviderType);
  } else if (localStorage.getItem(CUSTOMER_ROLE_TYPE) === "unassigned") {
    items = getItemsBasedOnRole(noRoleMenus, cloudProviderType);
  } else if (isReportsAccessUserFn()) {
    items = getItemsBasedOnRole(reportsAccessMenus, cloudProviderType);
  }
  let isAllowed = isAllowedRoute(items, url, featureFlag);

  //adding this because isAllowedRoute only verifies menu and sub-menu items and not if there is a single menu with top nav options
  if (isAppAccessUserFn()) {
    isAllowed = appAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  } else if (localStorage.getItem(CUSTOMER_ROLE_TYPE) === "unassigned") {
    isAllowed = noRoleAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  } else if (isReportsAccessUserFn()) {
    isAllowed = reportAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  }

  if (url === SWITCH_ORGNIZATION) {
    isAllowed = true;
  }

  return isAllowed;
};

export const isManagementRoute = (location, featureFlaggedList) => {
  const url = location.pathname.split("/")[2];

  const menuItems = getManagementSideMenuItems({ featureFlaggedList });
  let isMangement = false;

  menuItems.forEach((menuItem) => {
    if (menuItem.firstTab && menuItem.firstTab.includes(url)) {
      isMangement = true;
    } else if (isArrayWithLength(menuItem.children)) {
      menuItem.children.forEach((childMenu) => {
        if (childMenu.firstTab.includes(url)) {
          isMangement = true;
        }
      });
    }
  });

  return isMangement;
};
