import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AUTH_TYPE } from "../../constants";
import {
  getLoginLink,
  getPartnerContactUsLink,
  getPartnerType,
} from "../../../../helpers/auth-partner-helper-functions";

const icommon = i18next.getFixedT(null, "common");

const RedirectTo = ({ type = AUTH_TYPE.LOGIN }) => {
  const partnerType = getPartnerType();
  const loginLink = getLoginLink();

  const { t } = useTranslation("loginForm");
  if (type === AUTH_TYPE.SIGNUP) {
    return (
      <div className="mc-h6-regular clearfix bs4-mt-3 ">
        {t("demo")} &nbsp;
        <a
          className="mt-sm bs4-mt-2 link"
          href={getPartnerContactUsLink(partnerType)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Contact us")}
        </a>
        &nbsp; {icommon("here")}
      </div>
    );
  }
  return (
    <div className="mc-h6-regular clearfix bs4-mt-3">
      {t("takeMeto")}
      <Link className="bs4-ml-2 link" to={loginLink}>
        {t("loginTitle")}
      </Link>
    </div>
  );
};

export default RedirectTo;
