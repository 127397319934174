import React from "react";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import MCButton from "../../../../../components/MCButton/MCButton";
import { ACCOUNT_TYPES } from "../../../utils/account-constants";
import accountStyles from "../Account.module.scss";
import firstRunStlyes from "../../../../new-first-run/components/NewFirstRun.module.scss";

const ConfigureAWSAccountPermissions = ({
  firstRunView,
  toggleConnectAccountHelp,
  selectedAccountType,
  isLoading,
  showBackButton,
  handleOnPreviousStep,
  fetchCustomerAccountStatus,
  handleConnectAccount,
}) => {
  if (firstRunView) {
    return (
      <FREView
        toggleConnectAccountHelp={toggleConnectAccountHelp}
        showBackButton={showBackButton}
        isLoading={isLoading}
        handleOnPreviousStep={handleOnPreviousStep}
        handleConnectAccount={handleConnectAccount}
        selectedAccountType={selectedAccountType}
      />
    );
  }

  return (
    <AccountsView
      selectedAccountType={selectedAccountType}
      isLoading={isLoading}
      fetchCustomerAccountStatus={fetchCustomerAccountStatus}
    />
  );
};

export default ConfigureAWSAccountPermissions;

const FREView = ({
  toggleConnectAccountHelp,
  showBackButton,
  isLoading,
  handleOnPreviousStep,
  handleConnectAccount,
  selectedAccountType,
}) => {
  const { t } = useTranslation("addAccount");

  const { submittedFeedback } = useSelector(({ supportTicket }) => {
    return {
      submittedFeedback: supportTicket.isCreated,
    };
  }, shallowEqual);

  return (
    <>
      <div
        className={classnames(
          firstRunStlyes.MCHeading2,
          firstRunStlyes.Bold600,
          "bs4-mt-3",
          firstRunStlyes.WordLetterSpacing
        )}
      >
        {t("firstRun:connectAccountSubHeading")}
      </div>

      <ol className={classnames(firstRunStlyes.StepList, "mt-5")}>
        <li> {t("firstRun:connectAccountStep1")}</li>
        <li> {t("firstRun:connectAccountStep2")}</li>
        <li> {t("firstRun:connectAccountStep3")}</li>
      </ol>

      <div className={classnames("bs4-mt-3 mc-text-lg-regular")}>
        {t("firstRun:returnBackText")}
      </div>

      <div
        className={classnames(
          firstRunStlyes.ButtonPosition,
          selectedAccountType === ACCOUNT_TYPES.MANAGEMENT
            ? firstRunStlyes.ManagementAccountButtonPosition
            : ""
        )}
      >
        {showBackButton && (
          <MCButton
            className="bs4-ml-2 float-left"
            text={
              <>
                <i className="fa fa-caret-left bs4-mr-2" />
                {t("common:previous")}
              </>
            }
            onClick={handleOnPreviousStep}
            size="lg"
          />
        )}
        <MCButton
          size="lg"
          text={
            isLoading ? (
              t("templateConfigMsg")
            ) : (
              <>
                <i className="fa fa-lock bs4-mr-2" />
                {t("common:connect")}
              </>
            )
          }
          color={"primary"}
          onClick={handleConnectAccount}
          disabled={isLoading}
          className={classnames(accountStyles.robotoFont, "bs4-ml-2")}
        />
        {!isLoading && !submittedFeedback && (
          <MCButton
            size="lg"
            text={t("common:notNow")}
            onClick={toggleConnectAccountHelp}
            className={classnames(accountStyles.robotoFont, "bs4-ml-3")}
          />
        )}
      </div>
    </>
  );
};

const AccountsView = ({
  selectedAccountType,
  isLoading,
  fetchCustomerAccountStatus,
}) => {
  const { t } = useTranslation("addAccount");
  return (
    <div className="bs4-mt-2">
      <span
        className="btn btn-primary anchor-tag"
        role="button"
        onClick={fetchCustomerAccountStatus}
        disabled={isLoading}
      >
        {t("newAccountHeader2")}
      </span>
      {isLoading && (
        <span>
          <i className="fa fa-spin fa-spinner bs4-ml-2" />
          &nbsp;
          {t("templateConfigMsg")}
        </span>
      )}
      <div className="bs4-mt-4 mc-text-base-regular">
        <p>{t("configureInstructionStep2-1")}</p>
        {selectedAccountType === ACCOUNT_TYPES.MANAGEMENT ? (
          <p>
            <Trans
              i18nKey="addAccount:configureInstructionStep2-2-org"
              components={[<strong></strong>]}
            />
          </p>
        ) : (
          <p>{t("configureInstructionStep2-2-standalone")}</p>
        )}
        <p>{t("configureInstructionStep2-3")}</p>
      </div>
    </div>
  );
};
