import axios from "axios";
import { GLOBAL_CUSTOMER_TYPE } from "../../../../src/reducers/global-config";
import config from "../../../config";
import i18n from "../../../config/locale/i18n";
import { STANDARD_PLAN_ID } from "../../../config/service";
import {
  getLoggedInUserRefreshToken,
  logOutUser,
  setLoggedInUserData,
  updateLoggedInUserAccessToken,
} from "../../../helpers/account";
import {
  DEMO_PROJECT_OWNERS,
  DEMO_PROJECT_USERS,
} from "../../../network/helpers/project-constants.json";
import {
  CUSTOMER_ROLE_APP_ACCESS_ID,
  CUSTOMER_ROLE_DAY2_ADMIN_ID,
  CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID,
  CUSTOMER_ROLE_FULL_ACCESS_ID,
  CUSTOMER_ROLE_INFRA_ACCESS_ID,
  CUSTOMER_ROLE_OPERATION_ACCESS_ID,
  CUSTOMER_ROLE_REPORT_ACCESS_ID,
  CUSTOMER_ROLE_TYPE,
  CUSTOMER_TYPE,
  EMAIL,
  IS_USER_IN_FREE_MODE,
  MFA_CODE_REQUIRED_CHALLENGE,
  MFA_LOGIN_LOCKED,
  NEW_PASSWORD_REQUIRED_CHALLENGE,
  ROLE_ID,
  USERNAME,
} from "../../../utils/app-constants";
import { isArrayWithLength } from "../../../utils/array-methods";
import { postNotification } from "../../../utils/notification-message";
import { planType, PRODUCT_ID_DNC } from "../../../utils/subscription-plan";
import {
  AUTH_PAGE_UNLOADED,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_FORGOT_PASSWORD,
  CLEAR_LOGIN_STATE,
  CLEAR_RESEND_MESSAGE,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOGGED_IN_USER_DETAILS_FAILURE,
  LOGGED_IN_USER_DETAILS_REQUEST,
  LOGGED_IN_USER_DETAILS_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REDIRECT,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  MFA_CODE_REQUIRED_TO_LOGIN,
  PRE_LOGIN_REDIRECT,
  PRE_LOGIN_REQUEST,
  AUTH_CAPTCHA_VERIFICATION,
  AUTH_CAPTCHA_VERIFICATION_FAILED,
  PRE_LOGIN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  RESEND_CONFIRMATION_EMAIL,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_NEW_PASSWORD_REQUIRED,
  SUBSCRIPTION_ID,
  UPDATE_MFA_CONFIG,
  USER_LOGIN_DISABLED,
} from "../actionTypes/auth";
import { SIGNUP_PAGE_UNLOADED } from "../actionTypes/signup";
import {
  changePasswordApi,
  getLoggedInUserDetailsApi,
  getRefreshTokenApi,
  preSignInApi,
  resetPasswordApi,
  sendForgotPasswordLinkApi,
  signInApi,
  ssoSignInApi,
  userLogoutASessionApi,
} from "../services/auth";
import {
  requestToken,
  requestTokenFailure,
  requestTokenSuccess,
} from "./token";
import { getLoginLink } from "../../../helpers/auth-partner-helper-functions";
const getCustomerRoleType = (roleId) => {
  let roleType = "unassigned";
  switch (roleId) {
    case CUSTOMER_ROLE_APP_ACCESS_ID:
      roleType = "app-access";
      break;
    case CUSTOMER_ROLE_REPORT_ACCESS_ID:
      roleType = "report-access";
      break;
    case CUSTOMER_ROLE_OPERATION_ACCESS_ID:
      roleType = "operation-access";
      break;
    case CUSTOMER_ROLE_INFRA_ACCESS_ID:
      roleType = "infrastructure-access";
      break;
    case CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID:
      roleType = "department-access";
      break;
    case CUSTOMER_ROLE_FULL_ACCESS_ID:
      roleType = "full-access";
      break;
    case CUSTOMER_ROLE_DAY2_ADMIN_ID:
      roleType = "day2-admin-access";
      break;
    default:
      roleType = "unassigned";
      break;
  }

  return roleType;
};

export const authCaptchaVerification = () => {
  return {
    type: AUTH_CAPTCHA_VERIFICATION,
  };
};

export const authCaptchaVerificationFailed = () => {
  return {
    type: AUTH_CAPTCHA_VERIFICATION_FAILED,
  };
};

function requestPreLogin() {
  return {
    type: PRE_LOGIN_REQUEST,
  };
}

export function receivePreLogin() {
  return {
    type: PRE_LOGIN_SUCCESS,
  };
}

function preLoginRedirect(payload) {
  return {
    type: PRE_LOGIN_REDIRECT,
    payload,
  };
}

export const preSignIn = (creds) => async (dispatch) => {
  dispatch(requestPreLogin());
  dispatch(requestToken());
  if (creds && creds.Username.length > 0) {
    try {
      const res = await preSignInApi(creds);
      if (res && res.status) {
        const status = res.status;
        if (status === "SSO_AUTH") {
          dispatch(preLoginRedirect(res.redirect));
        } else if (status === "LOCAL_AUTH") {
          dispatch(receivePreLogin());
        }
      }
    } catch (err) {
      const error =
        err &&
        err.message &&
        JSON.parse(err.message) &&
        JSON.parse(err.message);
      let message = "";
      if (config.demoApp) {
        message = i18n.t("loginForm:invalidUsernameOrPassword");
      } else {
        message = error && error.data && error.data.ErrorMessage;
        if (message && message.includes("User not confirmed")) {
          return dispatch(resendConfirmationEmail());
        }
      }
      dispatch(loginError(message));
      dispatch(requestTokenFailure());
    }
  } else {
    dispatch(loginError(i18n.t("common:somethingWentWrong")));
    dispatch(requestTokenFailure());
  }
};

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function receiveLogin(customerDetails) {
  return {
    type: LOGIN_SUCCESS,
    payload: preprocessCustomerDetails(customerDetails),
  };
}

const preprocessCustomerDetails = (customerDetails) => {
  const _subscriptionData =
    customerDetails && customerDetails.SubscriptionMetadata;
  const _productIds = _subscriptionData && _subscriptionData.ProductIds;
  const _hasProductsAttached = _productIds && _productIds.length > 0;

  //new user will have no subscription attached
  const userHasNoPlans =
    customerDetails && !customerDetails.SubscriptionMetadata;

  const isATrialUser = _subscriptionData && _subscriptionData.IsTrial;

  const isFreemium =
    !isATrialUser &&
    _hasProductsAttached &&
    _productIds.length === 1 &&
    _productIds.includes(PRODUCT_ID_DNC);

  const isEnterpriseCustomer =
    !isATrialUser && _hasProductsAttached && _productIds.length === 4;

  const isUserInFreeMode =
    localStorage.getItem(IS_USER_IN_FREE_MODE) === "true";

  const _customerDetails = {
    customerType: customerDetails.CustomerType,
    isSelfManagedUser:
      customerDetails && customerDetails.CustomerType === STANDARD_PLAN_ID,
    isEnterpriseCustomer: isEnterpriseCustomer,
    isATrialUser: _subscriptionData && _subscriptionData.IsTrial,
    isFreemium: isFreemium,
    isTrialExpired: _subscriptionData && _subscriptionData.TrialExpired,
    hasNoPlans: userHasNoPlans,
    subscriptionDetails: customerDetails.SubscriptionMetadata,
    isLegacy: customerDetails.IsLegacy,
    roles: customerDetails.Roles,
    companyName: customerDetails.CompanyName,
    features: customerDetails.Features,
    isMFAEnabled: customerDetails.MFAEnabled,
    isMFAConfigured: customerDetails.MFAConfigured,
    mfaEnforcementType: customerDetails.MFAEnforcement,
    userId: customerDetails.UserId,

    // considering this as users active org
    usersActiveOrganizationId: customerDetails.OrganizationId,
    organizationId: customerDetails.OrganizationId,
    isUserInFreeMode: isUserInFreeMode,
  };
  return _customerDetails;
};

export const updateMFAConfigData = (payload) => ({
  type: UPDATE_MFA_CONFIG,
  payload,
});

export const receiveSubscriptionId = (payload) => ({
  type: SUBSCRIPTION_ID,
  payload,
});

export function setGlobalCustomerType(customerType) {
  return {
    type: GLOBAL_CUSTOMER_TYPE,
    payload: customerType,
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function resendConfirmationEmail() {
  return {
    type: RESEND_CONFIRMATION_EMAIL,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export const dispatchMFARequired = (payload) => ({
  type: MFA_CODE_REQUIRED_TO_LOGIN,
  payload,
});

export const dispatchLoginDisabled = (payload) => ({
  type: USER_LOGIN_DISABLED,
  payload,
});

const setDemoRoles = () => {
  if (config.demoApp) {
    let email = localStorage.getItem(EMAIL);
    if (DEMO_PROJECT_USERS.includes(email)) {
      localStorage.setItem(ROLE_ID, CUSTOMER_ROLE_APP_ACCESS_ID);
      localStorage.setItem(
        CUSTOMER_ROLE_TYPE,
        getCustomerRoleType(CUSTOMER_ROLE_APP_ACCESS_ID)
      );
    } else if (DEMO_PROJECT_OWNERS.includes(email)) {
      localStorage.setItem(ROLE_ID, CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID);
      localStorage.setItem(
        CUSTOMER_ROLE_TYPE,
        getCustomerRoleType(CUSTOMER_ROLE_APP_ACCESS_ID)
      );
    } else {
      localStorage.setItem(ROLE_ID, CUSTOMER_ROLE_DAY2_ADMIN_ID);
      localStorage.setItem(
        CUSTOMER_ROLE_TYPE,
        getCustomerRoleType(CUSTOMER_ROLE_DAY2_ADMIN_ID)
      );
    }
  }
};

const isFreeUser = (result) => {
  const subscriptionDetails = result.SubscriptionMetadata;
  const isATrialUser = subscriptionDetails && subscriptionDetails.IsTrial;
  const isTrialExpired =
    subscriptionDetails && subscriptionDetails.TrialExpired;
  const notAllowedToAccessFeatures = isTrialExpired || !isATrialUser;
  const allowedPlans =
    subscriptionDetails &&
    isArrayWithLength(subscriptionDetails.ProductIds) &&
    planType.find((p) => subscriptionDetails.ProductIds.includes(p));

  return !allowedPlans && notAllowedToAccessFeatures;
};

export const handleDispatchLogin = (result, username, isSSO) => (dispatch) => {
  const token = result.Token;
  const accessToken = result.AccessToken;
  const name = result.Name;
  const refreshToken = result.RefreshToken;
  const customerType = result.CustomerType;
  const subscriptionId =
    result.SubscriptionMetadata && result.SubscriptionMetadata.SubscriptionId;
  const roleId =
    result &&
    result.Roles &&
    result.Roles.length &&
    result.Roles[0] &&
    result.Roles[0].Role;
  localStorage.setItem(USERNAME, name);
  localStorage.setItem(EMAIL, username);
  localStorage.setItem(CUSTOMER_TYPE, customerType);
  localStorage.setItem(ROLE_ID, roleId);
  localStorage.setItem(CUSTOMER_ROLE_TYPE, getCustomerRoleType(roleId));
  localStorage.setItem(IS_USER_IN_FREE_MODE, isFreeUser(result));
  setDemoRoles();
  const userData = {
    Name: name,
    Token: token,
    RefreshToken: refreshToken,
    ...(!isSSO && { CustomerType: customerType }),
    AccessToken: accessToken,
  };
  setLoggedInUserData(userData);
  dispatch(receiveToken(token, accessToken));
  dispatch(receiveLogin(result));
  dispatch(requestTokenSuccess());

  dispatch(receiveSubscriptionId(subscriptionId));
  if (!isSSO) {
    dispatch(setGlobalCustomerType(customerType));
  }
};

export function loginUser(creds) {
  return (dispatch) => {
    dispatch(requestLogin());
    dispatch(requestToken());
    if (creds && creds.Username.length > 0 && creds.Password.length > 0) {
      signInApi(creds)
        .then((res) => {
          if (res.ChallengeName === NEW_PASSWORD_REQUIRED_CHALLENGE) {
            dispatch({
              type: SIGN_UP_NEW_PASSWORD_REQUIRED,
              payload: {
                username: creds.Username,
                session: res.Session,
              },
            });
          } else if (res.ChallengeName === MFA_CODE_REQUIRED_CHALLENGE) {
            const payload = {
              username: creds.Username,
              session: res.Session,
            };
            dispatch(dispatchMFARequired(payload));
          } else {
            dispatch(handleDispatchLogin(res, creds.Username));
          }
        })
        .catch((err) => {
          let message = "";
          if (config.demoApp) {
            message = i18n.t("loginForm:invalidUsernameOrPassword");
          } else {
            const error = JSON.parse(err.message);
            message = error.data.ErrorMessage;
            if (message.includes("User not confirmed")) {
              return dispatch(resendConfirmationEmail());
            } else if (message === MFA_LOGIN_LOCKED) {
              return dispatch(
                dispatchLoginDisabled({ username: creds.Username })
              );
            }
          }
          dispatch(loginError(message));
          dispatch(requestTokenFailure());
        });
    } else {
      dispatch(loginError(i18n.t("common:somethingWentWrong")));
      dispatch(requestTokenFailure());
    }
  };
}

const requestLoggedInUserDetails = () => ({
  type: LOGGED_IN_USER_DETAILS_REQUEST,
});

const loggedInUserDetailSuccess = (customerDetails) => ({
  type: LOGGED_IN_USER_DETAILS_SUCCESS,
  payload: preprocessCustomerDetails(customerDetails),
});

const loggedInUserDetailFailure = (payload) => ({
  type: LOGGED_IN_USER_DETAILS_FAILURE,
  payload: payload,
});

export const setLoggedInUserDetails = (customerDetails) => (dispatch) => {
  const customerType = customerDetails.CustomerType;
  localStorage.setItem(CUSTOMER_TYPE, customerType);
  const roleId =
    customerDetails &&
    customerDetails.Roles &&
    customerDetails.Roles.length &&
    customerDetails.Roles[0] &&
    customerDetails.Roles[0].Role;
  localStorage.setItem(ROLE_ID, roleId);
  localStorage.setItem(CUSTOMER_ROLE_TYPE, getCustomerRoleType(roleId));
  setDemoRoles();
  dispatch(loggedInUserDetailSuccess(customerDetails));
};

export const handleUserDetailsPooling =
  (intervalId) => (dispatch, getState) => {
    const {
      auth: { isFreemium, isTrialExpired },
    } = getState();
    if (isFreemium && !isTrialExpired) {
      dispatch(getLoggedInUserDetails());
    } else {
      clearInterval(intervalId);
      const successMessage = i18n.t("payment:requestTrialSuccessMessage");
      dispatch(postNotification(successMessage, "success"));
    }
  };

export const getLoggedInUserDetails =
  (successcallback = () => {}) =>
  (dispatch) => {
    dispatch(requestLoggedInUserDetails());
    getLoggedInUserDetailsApi()
      .then((result) => {
        const customerType = result.CustomerType;
        localStorage.setItem(CUSTOMER_TYPE, customerType);
        const roleId =
          result &&
          result.Roles &&
          result.Roles.length &&
          result.Roles[0] &&
          result.Roles[0].Role;
        localStorage.setItem(ROLE_ID, roleId);
        localStorage.setItem(CUSTOMER_ROLE_TYPE, getCustomerRoleType(roleId));
        localStorage.setItem(IS_USER_IN_FREE_MODE, isFreeUser(result));
        setDemoRoles();
        dispatch(loggedInUserDetailSuccess(result));
        successcallback();
      })
      .catch((error) => {
        let message = "";
        const err = JSON.parse(error.message);
        message = err && err.data && err.data.ErrorMessage;
        dispatch(loggedInUserDetailFailure(message));
      });
  };

export const ssologinUser =
  (code, state, error_description, error) => async (dispatch) => {
    dispatch(requestLogin());
    dispatch(requestToken());

    const req = {
      state: state,
    };
    if (code) {
      req.code = code;
    }
    if (error) {
      req.error = error;
    }
    if (error_description) {
      req.error_description = error_description;
    }

    if (state) {
      try {
        const res = await ssoSignInApi(req);
        if (res && res.status === "SSO_AUTH") {
          dispatch(preLoginRedirect(res.redirect));
        } else {
          dispatch(handleDispatchLogin(res, "email", true));
        }
      } catch (err) {
        let message = i18n.t("loginForm:unexpectedErrorOccured");
        if (config.demoApp) {
          message = i18n.t("loginForm:invalidUsernameOrPassword");
        } else {
          const error = JSON.parse(err.message);
          if (error && error.data) {
            message = error.data.ErrorMessage;
            if (message.includes("User not confirmed")) {
              return dispatch(resendConfirmationEmail());
            }
          }
        }
        dispatch(loginError(message));
        dispatch(requestTokenFailure());
      }
    } else {
      dispatch(loginError(i18n.t("common:somethingWentWrong")));
      dispatch(requestTokenFailure());
    }
  };

function requestForgotPassword() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
}

export function receiveForgotPassword(username) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    username: username,
  };
}

export function clearForgotPassword() {
  return {
    type: CLEAR_FORGOT_PASSWORD,
  };
}
function forgotPasswordError(payload) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload,
  };
}

export function forgotPassword(data) {
  return (dispatch) => {
    dispatch(requestForgotPassword());
    dispatch(requestToken());
    if (data) {
      sendForgotPasswordLinkApi(data)
        .then((res) => {
          dispatch(receiveForgotPassword(data.Username));
          dispatch(requestTokenSuccess());
        })
        .catch((err) => {
          const error = JSON.parse(err.message);
          const message = error.data.ErrorMessage;
          dispatch(forgotPasswordError(message));
          dispatch(requestTokenFailure());
        });
    } else {
      dispatch(forgotPasswordError(i18n.t("common:somethingWentWrong")));
      dispatch(requestTokenFailure());
    }
  };
}

function requestChangePassword() {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
}

export function receiveChangePassword() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

function changePasswordError(payload) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload,
  };
}

export function changePassword(data) {
  return (dispatch) => {
    dispatch(requestChangePassword());
    dispatch(requestToken());
    if (data) {
      changePasswordApi(data)
        .then((res) => {
          dispatch(receiveChangePassword());
          dispatch(requestTokenSuccess());
        })
        .catch((err) => {
          const error = JSON.parse(err.message);
          const message = error.data.ErrorMessage;
          dispatch(changePasswordError(message));
          dispatch(requestTokenFailure());
        });
    } else {
      dispatch(changePasswordError(i18n.t("common:somethingWentWrong")));
      dispatch(requestTokenFailure());
    }
  };
}

export const resetPassword = (data) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  dispatch(requestToken());
  const loginLink = getLoginLink();
  if (data) {
    resetPasswordApi(data)
      .then(() => {
        window.location.href = loginLink;
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
        });
        dispatch(requestTokenSuccess());
        dispatch(
          postNotification(i18n.t("loginForm:passwordChanged"), "success")
        );
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        const message = error.data.ErrorMessage;
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: message,
        });
        dispatch(requestTokenFailure());
      });
  } else {
    dispatch(changePasswordError(i18n.t("common:somethingWentWrong")));
    dispatch(requestTokenFailure());
  }
};

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function receiveToken(token, accessToken) {
  return (dispatch) => {
    localStorage.setItem("token", token);
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };
}

function logoutRedirect(payload) {
  return {
    type: LOGOUT_REDIRECT,
    payload,
  };
}

// Logs the user out
export function logoutUser() {
  const refreshToken = getLoggedInUserRefreshToken();
  const payload = { RefreshToken: refreshToken };
  const hasRefreshToken = refreshToken && refreshToken !== "undefined";
  return (dispatch) => {
    hasRefreshToken &&
      userLogoutASessionApi(payload)
        .then((result) => {
          logOutUser();
          dispatch(requestLogout());

          if (result && result.status === "SSO_LOGOUT") {
            dispatch(logoutRedirect(result.redirect));
          } else {
            dispatch(receiveLogout());
            dispatch(requestTokenFailure());
          }
        })
        .catch((error) => {
          logOutUser();
          dispatch(requestLogout());
          dispatch(receiveLogout());
          dispatch(requestTokenFailure());
        });
  };
}

function requestRefreshToken() {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
}

export function receiveRefreshToken() {
  return {
    type: REFRESH_TOKEN_SUCCESS,
  };
}

function refreshTokenError(payload) {
  return {
    type: REFRESH_TOKEN_FAILURE,
    payload,
  };
}

export function getRefreshToken() {
  return (dispatch) => {
    dispatch(requestRefreshToken());
    dispatch(requestToken());
    var payload = {};
    payload.RefreshToken = getLoggedInUserRefreshToken();

    if (!payload.RefreshToken || payload.RefreshToken.length === 0) {
      dispatch(logoutUser());
    }

    getRefreshTokenApi(payload)
      .then((res) => {
        const token = res.Token;
        const accessToken = res.AccessToken;
        updateLoggedInUserAccessToken(token, accessToken);
        dispatch(receiveToken(token, accessToken));
        dispatch(receiveRefreshToken(token));
        dispatch(requestTokenSuccess());
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        if (error.data) {
          const message = error.data.ErrorMessage;
          dispatch(refreshTokenError(message));
          dispatch(requestTokenFailure());
        }
      });
  };
}

export const clearLoginState = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_STATE,
  });
};

export const clearResendMessage = () => ({
  type: CLEAR_RESEND_MESSAGE,
});

export const clearLoginScreen = () => (dispatch) => {
  dispatch({
    type: AUTH_PAGE_UNLOADED,
  });
  dispatch({
    type: CLEAR_RESEND_MESSAGE,
  });
  dispatch({
    type: SIGNUP_PAGE_UNLOADED,
  });
};
