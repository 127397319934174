import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import s from "./HandleApiError.module.scss";

const HandleApiError = ({
  message,
  action,
  showRetry = true,
  topMargin,
  classNames,
  customButton,
  buttonClass,
  contentClass,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={classnames(topMargin && s.TopMargin, classNames)}>
      <div className={classnames("bs4-text-center", contentClass || "mt-5")}>
        <h4>{message}</h4>

        {showRetry &&
          (customButton ? (
            customButton
          ) : (
            <button
              onClick={action}
              className={classnames(
                "btn orange-text btn-sm button-without-border",
                buttonClass
              )}
            >
              <i className="fa fa-repeat bs4-mr-2"></i>
              {t("common:retry")}
            </button>
          ))}
      </div>
    </div>
  );
};

export default HandleApiError;
